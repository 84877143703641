import React from 'react';
import './App.css';
import { session } from './auth';

import './services/httprequest'
import { DfdNavBar, Sidebar } from './components/layout';
import RoutePage from './components/pages/RoutePage';
import PublicRoutePage from './components/pages/PublicRoutePage';

import {
  BrowserRouter as Router
} from "react-router-dom";
import { Box, Grommet } from 'grommet';
import { Grommet as GrommetIcon } from 'grommet-icons';
import { grommet } from "grommet/themes";
import { deepMerge } from 'grommet/utils';

const myCustomTheme = deepMerge(grommet, {
  spacing: 36,
  global: {
    colors: {
      brand: {
        "dark": "#93A8AC",
        "light": "#93A8AC"
      },
      "accent-1": "#2892D7",
      "accent-2": "#173753",
      "accent-3": "#7F557D",
      "accent-4": "#673C4F",
      "neutral-1": "#3C2A3B",
      "neutral-2": "#C3BABA",
      "neutral-3": "#7C7287",
      "neutral-4": "#6D95B7",
      "neutral-5": "#3A3E3B"
    },
    font: {
      family: "'Source Sans Pro', sans-serif"
    }
  },
  heading: {
    font: {
      family: "'Source Sans Pro', monospace;"
    }
  },
  list: {
    item: {
      pad: { horizontal: "large", vertical: "xsmall" },
      border: true
    }
  }
});

const publicSidebarItems = [
  {
    active: true,
    label: "Home",
    path: "/",
    exact: true
  }
];

const signedInSidebarItems = [
  {
    active: true,
    label: "Home",
    path: "/",
    exact: true
  },
  {
    active: true,
    label: "Manage account",
    path: "/manage-account"
  },
  {
    active: true,
    label: "Ingredients",
    path: "/ingredients"
  },
  {
    active: true,
    label: "Other Users",
    path: "/linkedusers"
  },
  {
    active: true,
    label: "Search",
    path: "/search"
  }
];

export class App extends React.Component {

  state = { user: null, customState: null, loggedIn: false };

  componentDidMount() {

    session.onUserUpdated(user => {
      this.setState({ loggedIn: user !== null });
      console.log('session from server', user)
    })

    session.hubListen()
  }

  onToggleSidebar = () =>
    this.setState({ showSidebar: !this.state.showSidebar });

  render() {
    const { loggedIn, showSidebar } = this.state;

    const sidebarItems = loggedIn ? signedInSidebarItems : publicSidebarItems

    return (
      <Grommet theme={myCustomTheme}>
        <Router>
          <DfdNavBar onToggleSidebar={this.onToggleSidebar} />
          <Box direction="row" flex>
            {loggedIn && <Box width="100%">
              <RoutePage />
            </Box>}
            {!loggedIn && <Box width="100%">
              <PublicRoutePage />
            </Box>}
            {showSidebar && (
              <Sidebar
                appIcon={<GrommetIcon color="brand" />}
                items={sidebarItems}
                onToggleSidebar={this.onToggleSidebar}
              />
            )}</Box>
        </Router>
      </Grommet>
    );
  }
}

export default App;
