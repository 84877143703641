import React from 'react';
import { Api } from '../../../services';
import { Box, Heading, Text, TextInput } from 'grommet';
import { FormEdit, FormCheckmark, FormClose, FormTrash } from 'grommet-icons';

const FormButton = ({ icon, onClick, ...rest }) => (<Box
    hoverIndicator
    onClick={onClick}
    margin={{ horizontal: "xsmall" }}
    background="accent-2"
    {...rest}>
    {icon}
</Box>)

const Ingredient = ({
    ing,
    editing,
    editable,
    edited,
    onEdit,
    onRemove,
    onRequestChange,
    onTextEdit }) => (
    <Box direction="row" justify="between" tag="li" border="bottom" pad="small">
        {!editing && <Text onClick={() => onEdit(ing)}>{ing.name}</Text>}
        {editing && <Box direction="row" align="center">
            <FormButton margin={{ right: "small" }} icon={<FormTrash />} onClick={() => onRemove(ing)} />
            <TextInput
                placeholder="New Ingredient"
                value={edited}
                onChange={event => onTextEdit(ing, event.target.value)}
            />
        </Box>}
        {editable && <Box direction="row" justify="end" align="center" margin={{ right: "small" }}>
            {!editing && <FormButton icon={<FormEdit />} onClick={() => onEdit(ing)} />}
            {editing && <FormButton icon={<FormCheckmark />} onClick={() => onRequestChange(ing)} />}
            {editing && <FormButton icon={<FormClose />} onClick={() => onEdit(ing)} />}
        </Box>}
    </Box>
)

const List = props => <Box fill tag="ul" border="top" {...props} />;

const SearchResults = ({ gotSearchResults, searchResults, onEdit, onRemove, onRequestChange, onTextEdit }) => {
    if (gotSearchResults) {
        if (searchResults.length === 0) {
            return <Box align="center" pad="medium">
                <Heading size="small">
                    No results
                </Heading>
            </Box>
        } else {
            onRemove = onRemove || (ing => { console.log('noop', ing) })
            onEdit = onEdit || (ing => { console.log('onEdit, noop', ing) })
            return (
                <List>
                    {searchResults.map(({ ingredient, editing, editable, edited }) =>
                        <Ingredient
                            key={ingredient.id}
                            ing={ingredient}
                            editable={editable}
                            editing={editing}
                            edited={edited}
                            onEdit={ing => onEdit(ing)}
                            onRemove={ing => onRemove(ing)}
                            onTextEdit={(ing, text) => onTextEdit(ing, text)}
                            onRequestChange={ing => onRequestChange(ing)}
                        />)}
                </List>
            )
        }
    } else {
        return (<div></div>)
    }
}

export const ViewIngredientsPageComponent = props => (<Box pad="medium">
    <Heading>Search for ingredients</Heading>
    <ViewIngredientsComponent initialSearch={true} />
</Box>)

export class ViewIngredientsComponent extends React.Component {
    constructor() {
        super()
        this.state = this.startingState();
    }

    searchTimeout;

    startingState() {
        return {
            canSearch: false,
            editedIngredients: {},
            editableIds: {},
            value: '',
            gotSearchResults: false,
            searchResults: []
        }
    }

    handleChange(event) {
        const value = event.target.value;

        this.searchAfterTimeout(value);

        this.setState({
            value,
            canSearch: value.length > 0
        })
    }

    searchAfterTimeout(value) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.handleSubmit(value)
        }, 300);
    }

    handleSubmit(value) {
        Api.searchIngredients(value)
            .then(({ ingredients }) => this.setState({
                gotSearchResults: true,
                searchResults: ingredients
            }))
            .catch(err => console.error(err));
    }

    componentDidMount() {
        if (!!this.props.initialSearch) {
            this.handleSubmit('')
        }
    }

    onToggleEdit(ing) {
        const { editableIds } = this.state;
        const id = ing.id;
        const val = editableIds[id];

        if (typeof val === 'undefined') {
            editableIds[id] = true;
        } else {
            editableIds[id] = !val;
        }

        this.setState({
            editableIds
        })
    }

    onTextEdit(ing, text) {
        const { editedIngredients } = this.state;
        const id = ing.id;

        editedIngredients[id] = text;

        this.setState({
            editedIngredients
        })
    }

    onRequestChange(ing) {
        const {
            editedIngredients
        } = this.state;

        const edited = editedIngredients[ing.id]

        console.log('update', edited);
    }

    render() {
        const { canEdit } = this.props;
        const {
            editableIds,
            editedIngredients,
            gotSearchResults,
            searchResults,
            value,
        } = this.state;

        const results = gotSearchResults
            ? searchResults.map(ingredient => ({
                ingredient,
                edited: editedIngredients[ingredient.id] || ingredient.name,
                editable: canEdit,
                editing: canEdit && !!editableIds[ingredient.id]
            }))
            : [];

        return <Box fill pad="medium">
            <TextInput
                placeholder="Search for ingredients"
                value={value}
                onChange={event => this.handleChange(event)}
            />
            <SearchResults
                gotSearchResults={gotSearchResults}
                searchResults={results}
                onRequestChange={ing => this.onRequestChange(ing)}
                onEdit={ing => this.onToggleEdit(ing)}
                onTextEdit={(ing, text) => this.onTextEdit(ing, text)} />
        </Box>
    }
}
