import React from 'react';
import { Box, Text, TextInput } from 'grommet';
import { FormCheckmark, FormClose, FormEdit, FormTrash } from 'grommet-icons';
import { DigitalText } from '../styled';
import { AddIngredientComponent } from './AddIngredientComponent'

export const IngredientView = ({ ingredients }) => (
    <Box margin={{ bottom: 'small' }}>
        {ingredients.map(({ ingredientid, name, preparation, amount }) => (
            <Box key={ingredientid} pad={{ left: 'small', bottom: 'xsmall', top: 'xsmall' }}>
                <Box align='start'><Text>{(amount ?? '') + ' ' + name}</Text></Box>
                <Box align='start' margin={{ left: 'small' }}><Text color='light-1'><i>{preparation}</i></Text></Box>
            </Box>
        ))}
    </Box>
)

export class Ingredient extends React.Component {
    constructor() {
        super()
        this.state = this.startingState();
    }

    startingState() {
        return {
            editingIngredient: false,
            updates: {
            }
        };
    }

    handleChange(property, event) {
        const value = event.target.value;
        const { updates } = this.state;

        updates[property] = value
        const newState = { updates }

        if (property === 'name') {
            newState.nameValidationError = false
        }

        this.setState(newState)
    }

    updateIngredient() {
        const { updateIngredient, ing } = this.props;
        const { updates } = this.state;

        if (updates.hasOwnProperty('name') && updates['name'] === '') {
            this.setState({ nameValidationError: true })
            return
        }

        if (Object.keys(updates).length > 0
            && typeof updateIngredient === 'function') {
            updateIngredient(ing, updates);
        }

        this.resetState();
    }

    toggle(property) {
        const newState = {};
        newState[property] = !this.state[property]
        this.setState(newState);
    }

    resetState() {
        this.setState(this.startingState());
    }

    render() {
        const { ing, removeIngredient } = this.props;
        const { editingIngredient, updates, nameValidationError } = this.state;

        const toggableOptions = editingIngredient
            ? [{
                icon: <FormCheckmark />,
                onClick: () => this.updateIngredient()
            }, {
                icon: <FormClose />,
                onClick: () => this.resetState()
            }]
            : [{
                icon: <FormEdit />,
                onClick: () => this.toggle('editingIngredient')
            }];

        if (!!removeIngredient) {
            toggableOptions.push({
                icon: <FormTrash />,
                onClick: () => removeIngredient(ing)
            })
        }

        const editableProperties = [{
            label: 'Name',
            property: 'name',
            validationError: nameValidationError
        },
        {
            label: 'Amount',
            property: 'amount'
        },
        {
            label: 'Notes',
            placeholder: 'notes',
            property: 'preparation'
        }]

        function valueFor(property) {
            if (typeof updates[property] !== 'undefined') {
                return updates[property];
            }
            return ing[property] || '';
        }

        return (
            <Box
                display="block"
                border="bottom"
                pad="small"
                height={{ min: 'unset' }}
            >
                <Box direction="row" justify="between">
                    <Box direction="row">
                        {!!ing.amount && <Text margin={{ horizontal: "small" }}>{ing.amount}</Text>}
                        {!editingIngredient && <Box>
                            <Text margin={{ horizontal: "small" }}>{ing.name}</Text>
                            <Text margin={{ left: 'medium' }}>{ing.preparation}</Text>
                        </Box>}
                    </Box>
                    {<Box direction="row" alignSelf="end">
                        {toggableOptions.map(({ icon, onClick }, idx) => <Box
                            hoverIndicator
                            key={idx}
                            onClick={onClick}
                            margin={{ horizontal: "xsmall" }}
                            background="accent-2">
                            {icon}
                        </Box>)}
                    </Box>}
                </Box>
                {editingIngredient &&
                    editableProperties.map(({ label, placeholder, property, validationError }) =>
                        <Box key={property}
                            height="65px"
                            width="100%"
                            direction="row"
                            alignSelf="end"
                            justify="between">
                            <Box width="35%"
                            >
                                <DigitalText
                                    margin={{ top: "small" }}>{label}</DigitalText>
                            </Box>
                            <Box height="40px" width="100%" border={validationError ? { color: 'red', style: 'solid' } : { side: "bottom" }}>
                                <TextInput
                                    plain
                                    placeholder={placeholder || label}
                                    value={valueFor(property)}
                                    onChange={event => this.handleChange(property, event)}
                                />
                            </Box>
                        </Box>)
                }
            </Box>
        )
    }
}

export class Ingredients extends React.Component {

    updateIngredient(...args) {
        const { updateIngredient } = this.props;
        if (typeof updateIngredient === 'function') {
            updateIngredient(...args)
        }
    }

    render() {
        const { ingredients, removeIngredient } = this.props;

        return <Box>
            <Box border="top">
                {ingredients.map(ing =>
                    <Ingredient
                        key={ing.ingredientid}
                        ing={ing}
                        removeIngredient={removeIngredient}
                        updateIngredient={(ing, u) => this.updateIngredient(ing, u)} />)}
            </Box>
        </Box>
    }
}

export class RecipeIngredients extends React.Component {

    updateIngredient(...args) {
        const { updateIngredient } = this.props;
        if (typeof updateIngredient === 'function') {
            updateIngredient(...args)
        }
    }

    render() {
        const { recipeId, ingredients, hasImage, addIngredient, addIngredientBatch, updateIngredient, removeIngredient, } = this.props;

        return <Box>
            <Box border="top">
                {!!ingredients && ingredients.map(ing =>
                    <Ingredient
                        key={ing.ingredientid}
                        ing={ing}
                        removeIngredient={removeIngredient}
                        updateIngredient={(ing, u) => updateIngredient(ing, u)} />)}

                {<AddIngredientComponent
                    recipeId={recipeId}
                    hasImage={hasImage}
                    addIngredient={(ingredientName) => addIngredient(ingredientName)}
                    addIngredientBatch={(input) => addIngredientBatch(input)}
                />}
            </Box>
        </Box>
    }
}
