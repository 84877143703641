import React from 'react';
import { Box, Button } from 'grommet';
import { Login } from 'grommet-icons';
import { ViewRecipesComponent } from '../list-recipes';

import { session } from '../../../../auth';

export class PublicHomeComponent extends React.Component {

    constructor() {
        super();
        this.state = {}
    }

    render() {

        return <Box>
            <Box background="brand">
                <Button
                    icon={<Login />}
                    label="Sign in to view your own recipes"
                    margin="small"
                    onClick={() => session.hostedUI()}
                    plain
                />
            </Box>
            <Box margin="small">
                <ViewRecipesComponent />
            </Box>
        </Box>
    }
}