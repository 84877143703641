import React from 'react';
import { Box, Markdown, Text } from 'grommet';
import { Share } from 'grommet-icons';

export class LinkComponent extends React.Component {
    constructor() {
        super();
        this.state = {
        };
    }

    render() {
        let { link } = this.props;

        return <Box>
            <Box direction="row">
                <Box>
                    <Box margin='medium' direction='row'>
                        <a href={link.url || ''} target='_blank' rel='noopener noreferrer'>
                            <Box
                                hoverIndicator
                                margin={{ horizontal: 'xsmall' }}
                            >
                                <Share />
                            </Box>
                        </a>
                        <Text margin={{ left: 'medium' }}>{link.name || ''}</Text>
                    </Box>
                    <Markdown>{link.notes || ''}</Markdown>
                </Box>
            </Box>
        </Box>;
    }
}
