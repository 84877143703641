import * as React from "react";
import { TextArea } from 'grommet';
import * as autosize from "autosize";

/**
 * A light replacement for built-in textarea component
 * which automaticaly adjusts its height to match the content
 * based on https://github.com/buildo/react-autosize-textarea/blob/master/src/TextareaAutosize.tsx
 */
export class ResizableTextArea extends React.Component {
    constructor() {
        super()
        this.state = this.startingState();
    }

    startingState() {
        return {
            lineHeight: null
        };
    }

    onResize(e) {
        if (this.props.onResize) {
            this.props.onResize(e);
        }
    };

    componentDidMount() {
        const file = document.getElementById('resizable-input')
        file && autosize(file);
    }

    componentWillUnmount() {
        const file = document.getElementById('resizable-input')
        file &&  autosize.destroy(file);
    }

    onChange(e) {
        const { onChange } = this.props;
        this.currentValue = e.currentTarget.value;
        onChange && onChange(e);
    };

    render() {
        const {
            maxRows,
            onChange,
            style,
            innerRef,
            children,
            ...props
        } = this.props;
        const { lineHeight } = this.state;

        const maxHeight = maxRows && lineHeight ? lineHeight * maxRows : null;

        return (
            <TextArea
                id="resizable-input"
                {...props}
                resize={false}
                onChange={(...args) => this.onChange(...args)}
                style={maxHeight ? { ...style, maxHeight } : style}
            />
        );
    }
}
