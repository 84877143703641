import React from 'react';
import { IngredientView } from './Ingredients'
import { Box, Button, Grid, Heading, ResponsiveContext, Markdown, Text } from 'grommet';
import { Close, FormAdd, FormNext } from 'grommet-icons';
import { ErrorComponent } from '../../layout';
import styled from 'styled-components';
import { LinksComponent } from './links';
import { AddReview, DisplayReview } from './EditReviews';
import { DisplayHistory } from './DisplayHistory';

const CenterHeading = styled(Heading)`
  text-align: center;
`;
const DigitalText = styled(Text)`
  font-family: 'Turret Road', cursive;
`;

const ListItem = props => <Box direction="row" tag="li" justify="between" border="bottom" pad="small" {...props} />;

class ViewMethodStep extends React.Component {

  render() {
    const {
      methodStep,
      stepIndex,
    } = this.props;

    const description = [methodStep.action, methodStep.time, methodStep.description]
      .filter(x => !!x).join('<br/>') || '_No description._'

    const action = `**${stepIndex + 1}.** ${description}`

    return <Box margin={{ bottom: "small" }}>
      <Box>
        <Box fill direction="row">
          <Markdown style={{ maxWidth: 'none' }}>{action}</Markdown>
        </Box>
      </Box>

      {!!methodStep.ingredients && methodStep.ingredients.length > 0 &&
        <Box fill tag="ul">
          {methodStep.ingredients.map((ing, idx) => <ListItem key={idx}>
            <Box direction="row">
              {!!ing.amount && <DigitalText margin={{ horizontal: "small" }}>{ing.amount}</DigitalText>}
              <DigitalText margin={{ horizontal: "small" }}>{ing.name}</DigitalText>
            </Box>
            {!!ing.preparation && <Box
              pad="small"
              margin={{ left: "large" }}
              direction="row">
              <DigitalText
                color="neutral-2"
                margin={{ horizontal: "small" }}>
                {ing.preparation}</DigitalText>
            </Box>}
          </ListItem>)}
        </Box>}
    </Box>
  }
}

const ViewSubRecipe = ({ subRecipe }) => <ListItem>
  <Box direction="row">
    {subRecipe.name}
  </Box>
  <Box
    hoverIndicator
    onClick={() => window.location = `/recipe/${subRecipe.recipeid}`}
    margin={{ right: "medium" }}>
    <FormNext />
  </Box>
</ListItem>

export class RecipeViewComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      addingReview: false,
      isLoaded: false,
      recipe: null,
      showSidebar: false,
    };
  }

  addReview(updates) {
    const { addReview } = this.props

    const date = new Date().toISOString()
    updates.date = date
    addReview(updates)
    this.setState({ addingReview: false })
  }

  toggle(property) {
    const newState = {};
    newState[property] = !this.state[property]
    this.setState(newState);
  }

  onToggleSidebar = () =>
    this.setState({ showSidebar: !this.state.showSidebar });

  onCloseUploadImageModal() {
    this.setState({ uploading: false });
    this.viewImage();
  }

  render() {
    const {
      error,
      recipe,
    } = this.props;

    if (!!error) {
      return <ErrorComponent error={error} />
    }

    const {
      viewIngredients,
      onCloseIngredients,
      updateReview,
      removeReview,
      imgUrl,
    } = this.props

    const {
      addingReview,
    } = this.state;

    const subRecipes = recipe.recipes || [];
    const method = recipe.method || [];
    const reviews = recipe.reviews || [];
    const history = recipe.history || [];
    const ingredients = method.reduce((prev, current, stepIndex) => {
      const ingredients = (current.ingredients || []).map(i => ({
        ...i,
        stepIndex
      }));
      prev.push(...ingredients);
      return prev
    }, []);

    const validMethod = method.filter(m => {
      return m.description?.length > 0
        || m.action?.length > 0
        || m.time?.length > 0
        || (ingredients !== null && ingredients.length > 0)
    })
    const showMethod = validMethod.length > 0
    const showIngredients = (recipe.ingredients || []).length > 0

    const viewableBy = recipe.metadata?.viewableBy || {}
    const viewableByUsers = viewableBy.everyone
      ? 'Everyone'
      : (viewableBy.users || []).length > 0 ? (viewableBy.users || []).join(', ') : 'Just You!';

    return <Box
      direction="row"
      background="light-1">
      <ResponsiveContext.Consumer>
        {(responsive) => (
          <Box
            width="100%"
            margin={{ top: 'small' }}>
            <Grid
              columns={(responsive === 'small') ? ['flex'] : ['flex', 'flex']}
              rows={['auto', 'auto', 'auto', 'auto']}
              gap='small'
            >
              <Box>
                <CenterHeading>
                  {recipe.name}
                  <Box direction="row" wrap={true}>
                    {(recipe.metadata?.tags || []).map(tag => <Box
                      key={tag}
                      direction="row"
                      align="center"
                      round={true}
                      background="accent-4"
                      margin="small"
                      pad={{ horizontal: "medium" }}>
                      <Text>{tag}</Text>
                    </Box>)}
                  </Box>
                </CenterHeading>
                {!!recipe.notes && <Box pad='10px' margin={{ bottom: 'medium' }} fill>
                  <Markdown style={{ maxWidth: 'none' }}>{recipe.notes}</Markdown>
                </Box>}
                <LinksComponent links={recipe.metadata?.recipeLinks ?? []} />
                {recipe.canEdit && <Box direction="row">
                  Viewable By: {viewableByUsers}
                </Box>}
              </Box>
              {imgUrl && <img src={imgUrl} width="100%" alt="Recipe" />}
              {viewIngredients &&
                <Box background='neutral-5' pad="small" overflow="scroll">
                  <Box direction="row" justify="between" align='center'>
                    <Heading size="small">All Ingredients</Heading>
                    <Close onClick={() => onCloseIngredients()} />
                  </Box>
                  <Box>
                    <Box border="top">
                      <IngredientView ingredients={ingredients} />
                    </Box>
                  </Box>
                </Box>}
              {showIngredients && <Box background='neutral-5' pad="small">
                <Heading size="small">Ingredients</Heading>
                {showIngredients && <IngredientView ingredients={recipe.ingredients} />}
              </Box>}
              {showMethod && <Box background='neutral-5' pad="small">
                <Heading size="small">Method</Heading>
                <Box>
                  {method.map((methodStep, stepIndex) => <ViewMethodStep
                    key={`${stepIndex}`}
                    methodStep={methodStep}
                    stepIndex={stepIndex}
                  />)}
                </Box>

              </Box>}
              {subRecipes.length > 0 &&
                <Box background='neutral-5' pad="small">
                  <Heading size="small">Recipes</Heading>
                  <Box fill tag="ul">
                    {subRecipes.map(r => <ViewSubRecipe
                      key={r.recipeid}
                      subRecipe={r}
                    />)}
                  </Box>
                </Box>}
              <Box background='neutral-5' pad="small" overflow="scroll">
                <Box direction="row" justify="between" align='center'>
                  <Heading size="small">Reviews</Heading>
                  <Button icon={<FormAdd />} onClick={() => this.setState({ addingReview: !addingReview })} />
                </Box>
                {addingReview && <AddReview
                  addReview={(...args) => this.addReview(...args)}
                />}
                <Box>
                  {reviews.map((review, i) => <DisplayReview
                    key={`${i}_${review.addedBy}_${review.date}_${reviews.length}`}
                    review={review}
                    updateReview={(update) => updateReview(i, update)}
                    removeReview={() => removeReview(i)}
                  />)}
                </Box>
              </Box>
              {!!history && history.length > 0 && <Box background='neutral-5' pad="small" overflow="scroll">
                <Heading size="small">History</Heading>
                <DisplayHistory history={history} />
              </Box>}
            </Grid>
          </Box>
        )
        }
      </ResponsiveContext.Consumer>
    </Box >
  }
}
