import React from 'react';
import { Api } from '../../services';
import { AddRecipeComponent } from './AddRecipeComponent';
import { Box, Heading } from 'grommet';

import styled from 'styled-components';

const CenterHeading = styled(Heading)`
  text-align: center;
`;

export class RecipesComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      isLoaded: false,
    };
  }

  addRecipe(recipeName) {
    Api.create(recipeName)
      .then(recipe => {
        window.location = `/recipe/${recipe.id}`
      })
      .catch(err => console.error('error adding', err))
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (<Box>
        <Box pad="small" background="neutral-4">
          <CenterHeading color="light-4">Add Recipe</CenterHeading>
          <AddRecipeComponent addRecipe={recipe => this.addRecipe(recipe)} />
        </Box>
      </Box>);
    }
  }
}
