import React from 'react';
import {
    Switch,
    Route,
    useParams,
} from "react-router-dom";
import { RecipesComponent } from "./RecipesComponent";
import { EditableRecipePageComponent, PublicRecipePageComponent } from './viewrecipe';
import { ViewIngredientsPageComponent } from './viewingredients';
import { ViewRecipesComponent } from './public/list-recipes/ViewRecipesComponent';
import { SearchRecipesComponent } from './search';
import { ManageAccountPageComponent } from './manage-account';

function ViewEditableRecipe() {
    let { id } = useParams();
    return (
        <EditableRecipePageComponent recipeId={id} />
    );
}

function ViewRecipe() {
    let { id } = useParams();
    return (
        <PublicRecipePageComponent recipeId={id} />
    );
}

export default function RoutePage() {
    return (
        <Switch>
            <Route exact path="/">
                <SearchRecipesComponent />
                <RecipesComponent />
            </Route>
            <Route path="/manage-account">
                <ManageAccountPageComponent />
            </Route>
            <Route path="/ingredients">
                <ViewIngredientsPageComponent />
            </Route>
            <Route path="/recipe/:id">
                <ViewEditableRecipe />
            </Route>
            <Route path="/public/recipe/:id">
                <ViewRecipe />
            </Route>
            <Route path="/linkedusers">
                <ViewRecipesComponent />
            </Route>
            <Route path="/search">
                <SearchRecipesComponent />
            </Route>
        </Switch>
    );
}
