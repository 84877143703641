import React, { Component } from "react";

import { Box, Button, Layer, ResponsiveContext } from "grommet";
import { FormClose } from "grommet-icons";

export class SidebarComponent extends Component {
    static contextType = ResponsiveContext;
    render() {
        const {
            children,
            showCloseSide = true,
            sideWidth,
            onClose,
            ...rest } = this.props;
        const size = this.context;
        const SidebarComponentType = size === "small" ? Layer : Box;
        const sidebarProps =
            size === "small"
                ? { full: true }
                : {
                    fill: "vertical",
                    width: sideWidth || "medium",
                    background: "light-2",
                    elevation: "xsmall"
                };

        const closeButton = showCloseSide || size === "small"
            ? <Box align="end">
                <Button icon={<FormClose />} onClick={onClose} />
            </Box> : null;

        return (
            <SidebarComponentType {...sidebarProps} {...rest}>
                <Box height={{ min: '50px' }}>{closeButton}</Box>
                <Box overflow="scroll">{children}</Box>
            </SidebarComponentType >
        );
    }
}
