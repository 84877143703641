import React from 'react';
import { Box } from 'grommet';
import { Share, FormTrash } from 'grommet-icons';
import { EditButton } from './EditButton';
import { EditableBoxComponent } from '../../../layout/EditableBoxComponent';

export class EditLinkComponent extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    delete() {
        let { removeLink } = this.props;
        removeLink();
    }

    render() {
        let { link, updateLink } = this.props;

        return <Box>
            <Box direction="row" justify="between" align="center" fill>
                <EditableBoxComponent
                    placeholder="Update link name"
                    value={link.name}
                    update={event => updateLink('name', event)}
                    width='35%' />
                <EditableBoxComponent
                    placeholder="Update link url"
                    value={link.url}
                    update={event => updateLink('url', event)}
                    width='45%' />
                <a href={link.url} target='_blank' rel='noopener noreferrer'>
                    <Share />
                </a>
                <EditButton
                    icon={<FormTrash />}
                    onClick={() => this.delete()} />
            </Box>
            <Box width="100%" border={{ side: "bottom" }}>
                <EditableBoxComponent
                    placeholder="Update link notes"
                    value={link.notes}
                    update={event => updateLink('notes', event)}
                    multiline={true} />
            </Box>
        </Box>;
    }
}
