import React from 'react';
import { Accordion, AccordionPanel, Box, Table, TableBody, TableHeader, TableRow, TableCell, Text } from 'grommet';

function AsDate(dateString) {
    return new Date(dateString).toLocaleDateString();
}

export class DisplayHistory extends React.Component {

    render() {
        let { history } = this.props;
        const converted = history
            .map(h => ({ ...h, date: AsDate(h.changedOn) }))
        const groups = Object.groupBy(converted, ({ date, changedBy }) => `${date} [${changedBy}]`)
        const grouped = Object.keys(groups).map(g => ({
            date: g,
            history: groups[g]
        }))

        return <>
            {grouped.map((group, i) =>
                <Box key={i} border="top" pad="medium">
                    <Box direction="row" justify="between" margin={{ bottom: 'medium' }}>
                        <Box direction="row" justify="between" width='fill'>
                            <Text weight='bold'>{group.date}
                                {!!group.changedBy && ` [${group.changedBy}]`}
                            </Text>
                        </Box>
                    </Box>
                    <Box margin={{ top: 'medium', left: 'medium' }}>
                        <Accordion>
                            {group.history.map((h, i) =>
                                <AccordionPanel key={i} label={h.updateValueType + ' ' + h.updateValue + ''} >
                                    <Box pad="medium" background="light-2">
                                        {h.changes.length === 0 && <Text>No changes</Text>}
                                        {h.changes.length > 0 && <Table>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableCell align='start'><Text></Text></TableCell>
                                                    <TableCell align='start'><Text>From</Text></TableCell>
                                                    <TableCell align='start'><Text>To</Text></TableCell>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {h.changes.map(({ property, previousValue, newValue }) => (
                                                    <TableRow key={property}>
                                                        <TableCell align='start'><Text>{property}</Text></TableCell>
                                                        <TableCell align='start'><Text>{previousValue}</Text></TableCell>
                                                        <TableCell align='start'><Text>{newValue}</Text></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>}
                                    </Box>
                                </AccordionPanel>
                            )}
                        </Accordion>
                    </Box>

                </Box>
            )
            }
        </>
    }
}
