import React, { Component } from "react";

import { Button } from "grommet";
import { User, Home, Group, Search, Test } from "grommet-icons";

import { SidebarComponent } from './SidebarComponent';

import { UserInfo } from './userinfo';

const icons = label => {
  if (label === "Home") {
    return <Home />;
  } else if (label === "Manage account") {
    return <User />;
  } else if (label === "Ingredients") {
    return <Test />;
  } else if (label === "Other Users") {
    return <Group />;
  } else if (label === "Search") {
    return <Search />;
  }
}

export class Sidebar extends Component {

  render() {
    const { items = [], onToggleSidebar, ...rest } = this.props;

    return (
      <SidebarComponent
        showCloseSide={false}
        onClose={onToggleSidebar}
        {...rest}>
        <UserInfo />
        {items.map(({ label, path }) => (
          <Button
            href={path}
            key={label}
            icon={icons(label)}
            label={label}
            margin="small"
            onClick={onToggleSidebar}
            plain
          />
        ))}
      </SidebarComponent>
    );
  }
}
