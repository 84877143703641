import React from 'react';
import { Box, Button, TextInput } from 'grommet';
import { Cafeteria } from 'grommet-icons';

export class AddRecipeComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            error: null,
            value: '',
            canSubmit: false
        };
    }

    handleChange(event) {
        this.setState({
            value: event.target.value,
            canSubmit: event.target.value.length > 0
        })
    }

    render() {
        const { addRecipe } = this.props;
        const { canSubmit, value } = this.state;
        return <Box pad="medium">
            <Box
                direction="row"
                align="center"
                pad={{ horizontal: "small", vertical: "xsmall" }}
                round="small"
                background="light-4">
                <TextInput
                    plain
                    placeholder="Add new recipe"
                    value={value}
                    onChange={event => this.handleChange(event)}
                    onSubmit={() => addRecipe(this.state.value)}
                />
                <Cafeteria disabled={!canSubmit} />
                <Button disabled={!canSubmit} onClick={ev => addRecipe(this.state.value)}>Add</Button>
            </Box>
        </Box>
    }
}
