import Amplify  from 'aws-amplify';

Amplify.configure({
    Auth: {

        region: 'eu-west-2',

        userPoolId: 'eu-west-2_DHttvQ5ba',

        userPoolWebClientId: '52inso6i2h2i2oabattjpvttic',

        mandatorySignIn: false,
        cookieStorage: {
            domain: '.maisiesadler.co.uk',
            path: '/',
            expires: 365,
            secure: true
        },

        oauth: {
            domain: 'auth.maisiesadler.co.uk',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://www.digitalfridgedoor.maisiesadler.co.uk/',
            redirectSignOut: 'https://www.digitalfridgedoor.maisiesadler.co.uk/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});

// You can get the current config object
// import Amplify, { Auth } from 'aws-amplify';
// const currentConfig = Auth.configure();
// console.log('config', currentConfig)

// async function SignIn() {
//     try {
//         const user = await Auth.signIn(username, password);
//         if (user.challengeName === 'SMS_MFA' ||
//             user.challengeName === 'SOFTWARE_TOKEN_MFA') {
//             // You need to get the code from the UI inputs
//             // and then trigger the following function with a button click
//             const code = getCodeFromUserInput();
//             // If MFA is enabled, sign-in should be confirmed with the confirmation code
//             const loggedUser = await Auth.confirmSignIn(
//                 user,   // Return object from Auth.signIn()
//                 code,   // Confirmation code  
//                 mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
//             );
//         } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
//             const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
//             // You need to get the new password and required attributes from the UI inputs
//             // and then trigger the following function with a button click
//             // For example, the email and phone_number are required attributes
//             const { username, email, phone_number } = getInfoFromUserInput();
//             const loggedUser = await Auth.completeNewPassword(
//                 user,              // the Cognito User Object
//                 newPassword,       // the new password
//                 // OPTIONAL, the required attributes
//                 {
//                     email,
//                     phone_number,
//                 }
//             );
//         } else if (user.challengeName === 'MFA_SETUP') {
//             // This happens when the MFA method is TOTP
//             // The user needs to setup the TOTP before using it
//             // More info please check the Enabling MFA part
//             Auth.setupTOTP(user);
//         } else {
//             // The user directly signs in
//             console.log(user);
//         }
//     } catch (err) {
//         if (err.code === 'UserNotConfirmedException') {
//             // The error happens if the user didn't finish the confirmation step when signing up
//             // In this case you need to resend the code and confirm the user
//             // About how to resend the code and confirm the user, please check the signUp part
//         } else if (err.code === 'PasswordResetRequiredException') {
//             // The error happens when the password is reset in the Cognito console
//             // In this case you need to call forgotPassword to reset the password
//             // Please check the Forgot Password part.
//         } else if (err.code === 'NotAuthorizedException') {
//             // The error happens when the incorrect password is provided
//         } else if (err.code === 'UserNotFoundException') {
//             // The error happens when the supplied username/email does not exist in the Cognito user pool
//         } else {
//             console.log(err);
//         }
//     }
// }
// // // For advanced usage
// // // You can pass an object which has the username, password and validationData which is sent to a PreAuthentication Lambda trigger
// // Auth.signIn({
// //     username, // Required, the username
// //     password, // Optional, the password
// //     validationData, // Optional, a random key-value pair map which can contain any key and will be passed to your PreAuthentication Lambda trigger as-is. It can be used to implement additional validations around authentication
// // }).then(user => console.log(user))
// //     .catch(err => console.log(err));