import React from 'react';
import {
    Switch,
    Route,
    useParams,
} from "react-router-dom";
import { PublicRecipePageComponent } from './viewrecipe';
import { PublicHomeComponent } from './public/home';

function ViewRecipe() {
    let { id } = useParams();
    return (
        <PublicRecipePageComponent recipeId={id} public={true} />
    );
}

export default function PublicRoutePage() {
    return (
        <Switch>
            <Route exact path="/">
                <PublicHomeComponent />
            </Route>
            <Route path="/public/recipe/:id">
                <ViewRecipe />
            </Route>
        </Switch>
    );
}
