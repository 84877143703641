import React from 'react';
import { Box } from 'grommet';
import { LinkComponent } from './LinkComponent';

export class LinksComponent extends React.Component {
    constructor() {
        super()
        this.state = {
        };
    }

    render() {
        let { links } = this.props;

        links = links || []

        return <Box>
            <Box pad={{ horizontal: 'medium' }}>
                {links.map((l, i) => <LinkComponent
                    key={l.url}
                    link={l} />)}
            </Box>
        </Box>
    }
}
