import React from 'react';
import styled from 'styled-components';
import { Api } from '../../../services';
import { Box, Button, Text, TextInput } from 'grommet';
import { StatusGood } from 'grommet-icons';
import AddButton from './AddButton';

const DigitalText = styled(Text)`
  font-family: 'Turret Road', cursive;
`;

const ListItem = props => <Box
    tag="li"
    direction="row"
    justify="between"
    pad={{ left: "large", vertical: "medium" }}
    border={{ size: "small", side: "bottom" }}
    {...props} />;

const Suggestions = ({
    showSuggestions,
    suggestions,
    onSuggestionSelected }) => (
    <Box fill tag="ul">
        {showSuggestions && suggestions.map((suggestion) => <ListItem
            key={suggestion.name}
            onClick={() => onSuggestionSelected(suggestion)}
        >
            <DigitalText>{suggestion.name}</DigitalText>
        </ListItem>)}
        {showSuggestions && suggestions.length === 0 && <ListItem>
            <DigitalText>
                No results - Add a new recipe to find it here!
            </DigitalText>
        </ListItem>}
    </Box >
)

class SearchSubRecipesComponent extends React.Component {
    constructor() {
        super()
        this.state = this.startingState();
    }

    searchTimeout;

    startingState() {
        return {
            value: '',
            showSuggestions: false,
            gotSearchResults: false,
            searchResults: [],
            showAddNewIngredient: false,
        }
    }

    handleChange(event) {
        const value = event.target.value;

        this.searchAfterTimeout(value);

        this.updateValue(value);
        this.setState({
            showSuggestions: false,
        })
    }

    searchAfterTimeout(value) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.search(value)
        }, 300);
    }

    search(value) {
        if (value.length > 0) {
            Api.recipes(value)
                .then(({ recipes }) => this.setState({
                    showSuggestions: true,
                    gotSearchResults: true,
                    searchResults: recipes
                }))
                .catch(r => console.log(r));
        }
    }

    onSubmit() {
        const {
            canSubmit,
            selectedSuggestion
        } = this.state;

        if (canSubmit && typeof this.props.onSubmit === 'function') {
            this.props.onSubmit(selectedSuggestion);
            this.updateValue('');
        }
    }

    onSuggestionSelected(suggestion) {
        this.updateValue(suggestion.name)
        this.setState({
            showSuggestions: false,
        })
    }

    updateValue(value) {
        const {
            gotSearchResults,
            searchResults,
        } = this.state;

        let selectedSuggestion = null;

        if (gotSearchResults) {
            const found = searchResults.find(sr => sr.name === value);
            selectedSuggestion = found;
        }

        this.setState({
            value,
            canSubmit: !!selectedSuggestion,
            selectedSuggestion
        })
    }

    render() {
        const {
            showSuggestions,
            canSubmit,
            searchResults,
            value
        } = this.state;

        const suggestions = (searchResults || []);

        return <Box fill pad="small" margin={{ horizontal: "medium" }}>
            <Box
                direction="row"
                justify="end">
                <TextInput
                    placeholder="Search for recipe"
                    value={value}
                    onChange={event => this.handleChange(event)}
                />
                {canSubmit && <Button
                    icon={<StatusGood />}
                    onClick={() => this.onSubmit()}
                />}
            </Box>

            <Box direction="row">
                <Suggestions
                    showSuggestions={showSuggestions}
                    suggestions={suggestions}
                    onSuggestionSelected={ing => this.onSuggestionSelected(ing)} />
            </Box>
        </Box>
    }
}

export class AddSubRecipeComponent extends React.Component {
    constructor() {
        super()
        this.state = this.startingState();
    }

    startingState() {
        return {
            adding: false
        }
    }

    toggleAdding() {
        this.setState({
            adding: !this.state.adding
        })
    }

    onSubmit(ing) {
        if (typeof this.props.onSubmit === 'function') {
            this.props.onSubmit(ing)
        }
        this.setState(this.startingState())
    }

    render() {
        const {
            adding } = this.state;

        if (adding) {
            return <SearchSubRecipesComponent onSubmit={ing => this.onSubmit(ing)} />
        } else {
            return <Box direction="row" align="center" justify="between">
                <AddButton
                    label="Add Sub Recipe"
                    onClick={() => this.toggleAdding()} />
            </Box>
        }
    }
}
