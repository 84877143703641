import React from 'react';
import { Box, CheckBox } from 'grommet';

export class ViewableByComponent extends React.Component {
    constructor() {
        super()
        this.state = {
        };
    }

    render() {
        const {
            updateViewableByEveryone,
            viewableBy } = this.props;

        const viewableByUsers = viewableBy.everyone
            ? 'Everyone'
            : (viewableBy.users || []).length > 0 ? (viewableBy.users || []).join(', ') : 'Just You!';

        const onChange = e => updateViewableByEveryone(e.target.checked)

        return <Box direction="row">
            <CheckBox
                toggle
                checked={viewableBy.everyone}
                onChange={onChange}
                label={`Viewable By: ${viewableByUsers}`} />
        </Box>
    }
}
