import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Button } from 'grommet';
import { More } from 'grommet-icons';

const DigitalHeading = styled(Heading)`
  font-family: 'Turret Road', sans-serif;
`;

export class DfdNavBar extends React.Component {
    render() {
        const { onToggleSidebar } = this.props;
        return <Box tag="header"
            direction="row"
            background="brand"
            align="center"
            elevation="medium"
            justify="between">
            <Button href="/">
                <Box flex={false}
                    direction="row"
                    align="center"
                    margin={{ left: "small" }}>
                    <img
                        alt=""
                        src="/fridge.svg"
                        width="55"
                        height="35"
                        className="d-inline-block align-top"
                    />{' '}
                    <DigitalHeading color="light-2">Digital Fridge Door</DigitalHeading>
                </Box>
            </Button>
            <Box margin="medium">
                <More color="light-2" onClick={onToggleSidebar} />
            </Box>
        </Box>
    }
}