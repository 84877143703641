import React from 'react';
import { Box, Button, Heading, Menu, Text } from 'grommet';
import { Bar, FormCheckmark, FormClose, FormEdit } from 'grommet-icons';
import { ErrorComponent } from '../../layout';
import { RecipeEditComponent } from './RecipeEditComponent';
import { RecipeViewComponent } from './RecipeViewComponent';
import { SubHeading } from '../styled';

export class RecipeComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoaded: false,
      recipe: null,
      editing: false,
      viewIngredients: false,
      confirmingRemove: false,
      showSidebar: false,
    };
  }

  toggle(property) {
    const newState = {};
    newState[property] = !this.state[property]
    this.setState(newState);
  }

  onToggleSidebar = () =>
    this.setState({ showSidebar: !this.state.showSidebar });

  render() {
    const {
      loading,
      error,
      recipe,
      suggestedTags,
    } = this.props;

    if (!!error) {
      return <ErrorComponent error={error} />
    }

    if (loading) {
      return <Box align="center" pad="medium">
        <Heading size="small">
          Loading recipe <Bar />
        </Heading>
      </Box >
    }

    const {
      rename,
      updateNotes,
      addMethodStep,
      addMethodSteps,
      updateMethodStep,
      removeMethodStep,
      addStepIngredient,
      updateStepIngredient,
      removeStepIngredient,
      addIngredient,
      addIngredientBatch,
      updateIngredient,
      removeIngredient,
      addSubRecipe,
      removeSubRecipe,
      addTag,
      removeTag,
      updateViewableByEveryone,
      addLink,
      updateLink,
      removeLink,
      addReview,
      updateReview,
      removeReview,
      removeRecipe,
      toggleRecordChanges,
      imgUrl,
      reloadRecipe,
      loadFromGousto,
    } = this.props

    const {
      editing,
      viewIngredients,
      confirmingRemove,
    } = this.state;

    const menuOptions = [{
      label: 'Copy recipe link',
      onClick: () => {
        let href = window.location.href
        href = href.replace('/recipe/', '/public/recipe/')
        href = href.replace('public/public', 'public')
        navigator.clipboard.writeText(href);
      },
    }]

    if (!editing) {
      menuOptions.push({
        label: viewIngredients ? 'Hide all ingredients' : 'Show all ingredients',
        onClick: () => this.toggle('viewIngredients'),
      })

      menuOptions.push({
        label: recipe.metadata?.record_changes ? 'Stop recording changes' : 'Start recording changes',
        onClick: () => toggleRecordChanges(!recipe.metadata?.record_changes),
      })
    }

    if (recipe.canEdit) {
      menuOptions.push({
        label: 'Delete Recipe',
        onClick: () => this.setState({ confirmingRemove: true }),
      })
    }

    return <Box
      background="light-1">
      <Box direction="row" justify="end">
        <Menu
          label="More Options"
          items={menuOptions}
        />
        {recipe.canEdit && <Box
          direction="row"
          pad="small"
          onClick={() => this.toggle('editing')}
        >
          {editing && <>Finish Editing <FormClose /></>}
          {!editing && <>Edit <FormEdit /></>}
        </Box>}
      </Box>
      <Box
        width="100%"
        margin={{ top: 'small' }}>
        {confirmingRemove &&
          <Box
            background="accent-2"
            margin={{ bottom: 'medium' }}
            pad={{ left: 'medium' }}>
            <SubHeading>Removing Recipe</SubHeading>
            <Box>
              <Text>Are you sure?</Text>
              <Box direction="row" justify="around">
                {[{
                  label: 'Yes',
                  icon: <FormCheckmark />,
                  onClick: () => removeRecipe(recipe.id)
                }, {
                  label: 'No',
                  icon: <FormClose />,
                  onClick: () => this.setState({ confirmingRemove: false })
                }].map(({ icon, label, onClick }, idx) =>
                  <Button
                    key={idx}
                    label={label}
                    margin="xsmall"
                    alignSelf="end"
                    color="accent-2"
                    icon={icon}
                    onClick={onClick} />
                )}
              </Box>
            </Box>
          </Box>}
        {!editing && <RecipeViewComponent
          recipe={recipe}
          error={error}
          imgUrl={imgUrl}
          viewIngredients={viewIngredients}
          onCloseIngredients={() => this.toggle('viewIngredients')}
          addReview={(...args) => addReview(...args)}
          updateReview={(...args) => updateReview(...args)}
          removeReview={(...args) => removeReview(...args)}
        />}
        {editing && <RecipeEditComponent
          recipe={recipe}
          error={error}
          suggestedTags={suggestedTags}
          rename={(...args) => rename(...args)}
          updateNotes={(...args) => updateNotes(...args)}
          addMethodStep={(...args) => addMethodStep(...args)}
          addMethodSteps={(...args) => addMethodSteps(...args)}
          updateMethodStep={(...args) => updateMethodStep(...args)}
          removeMethodStep={(...args) => removeMethodStep(...args)}
          addStepIngredient={(...args) => addStepIngredient(...args)}
          updateStepIngredient={(...args) => updateStepIngredient(...args)}
          removeStepIngredient={(...args) => removeStepIngredient(...args)}
          addIngredient={(...args) => addIngredient(...args)}
          addIngredientBatch={(...args) => addIngredientBatch(...args)}
          updateIngredient={(...args) => updateIngredient(...args)}
          removeIngredient={(...args) => removeIngredient(...args)}
          addSubRecipe={(...args) => addSubRecipe(...args)}
          removeSubRecipe={(...args) => removeSubRecipe(...args)}
          addTag={(...args) => addTag(...args)}
          removeTag={(...args) => removeTag(...args)}
          updateViewableByEveryone={(...args) => updateViewableByEveryone(...args)}
          addLink={(...args) => addLink(...args)}
          updateLink={(...args) => updateLink(...args)}
          removeLink={(...args) => removeLink(...args)}
          removeRecipe={(...args) => removeRecipe(...args)}
          reloadRecipe={(...args) => reloadRecipe(...args)}
          loadFromGousto={(...args) => loadFromGousto(...args)}
        />}
      </Box>
    </Box>
  }
}
