import React from 'react';
import { Box, TextInput } from 'grommet';
import { FormCheckmark } from 'grommet-icons';
import { EditButton } from '../pages/viewrecipe/links/EditButton';
import { ResizableTextArea } from '.';

export class EditableBoxComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            userChanged: false
        };
    }

    handleChange(event) {
        const updatedValue = event.target.value;
        this.setState({
            updatedValue,
            userChanged: true,
        });
    }

    update() {
        let { value, update } = this.props;
        let { updatedValue, userChanged } = this.state;
        if (typeof update === 'function' && userChanged && updatedValue !== value) {
            update(updatedValue);
        }
    }

    componentDidUpdate(props, state) {
        let { value } = props;
        let { initialValue } = state;

        if (value !== initialValue) {
            this.setState({ initialValue: value, userChanged: false })
        }
    }

    render() {
        let { value, placeholder, width, multiline } = this.props;
        let { updatedValue, userChanged } = this.state;

        updatedValue = userChanged ? updatedValue : (value || '').trim();

        const toggableOptions = [];

        if (userChanged && updatedValue !== value) {
            toggableOptions.push({
                icon: <FormCheckmark />,
                onClick: () => this.update()
            });
        }

        return <Box direction="row" justify="between" align="center" width={width}>
            <Box border={{ size: "small", side: "bottom" }} fill={true}>
                {multiline
                    ? <ResizableTextArea
                        plain
                        placeholder={placeholder}
                        size='medium'
                        value={updatedValue}
                        onChange={event => this.handleChange(event)}
                        onSubmit={() => this.update()} />
                    : <TextInput
                        placeholder={placeholder}
                        plain
                        value={updatedValue}
                        onChange={event => this.handleChange(event)}
                        onSubmit={() => this.update()} width='100%' />}
            </Box>
            <Box direction="row" justify="end">
                {toggableOptions.map(({ icon, onClick }, idx) => <EditButton
                    key={idx}
                    icon={icon}
                    onClick={onClick} />)}
            </Box>
        </Box>;
    }
}
