import React from 'react';
import { Api, CancelToken } from '../../../services';
import { Box, Heading, FormField } from 'grommet';
import { EditableBoxComponent } from '../../layout';

import styled from 'styled-components';

const CenterHeading = styled(Heading)`
  text-align: center;
`;


export class ManageAccountPageComponent extends React.Component {
    constructor() {
        super()
        this.state = {
        };
    }


    componentDidMount() {
        this.cancelToken = new CancelToken();

        Api.getUserview(this.cancelToken)
            .then((userview) => {
                this.setState({
                    userview,
                });
            })
            .catch(err => {
                if (!this._isCanceled) {
                    console.log('error getting userview', err);
                }
            });
    }

    async updateUserview(updates) {
        const userview = await Api.updateUserview(updates);
        this.setState({
            userview,
        });
    }

    render() {
        const { userview } = this.state
        console.log('userview', userview)

        if (!userview) {
            return <Box>Loading</Box>
        }

        return <Box pad="medium" >
            <CenterHeading>Manage your account</CenterHeading>
            <FormField label="Update display name">
                <EditableBoxComponent
                    placeholder='Enter your display name'
                    value={userview.nickname}
                    update={value => this.updateUserview({ nickname: value })}
                />
            </FormField>
        </Box>
    }
}

