import React from 'react';
import { AddSubRecipeComponent } from './AddSubRecipeComponent';
import { Box, Text } from 'grommet';
import { FormNext, FormTrash } from 'grommet-icons';

const ListItem = props => <Box direction="row" tag="li" justify="between" border="bottom" pad="small" {...props} />;

const SubRecipe = ({ subRecipe, removeSubRecipe }) => <ListItem>
    <Box direction="row">
        <Box
            hoverIndicator
            onClick={() => removeSubRecipe(subRecipe)}
            margin={{ right: "medium" }}
            background="accent-2">
            <FormTrash />
        </Box>
        {subRecipe.name}
    </Box>
    <Box
        hoverIndicator
        onClick={() => window.location = `/recipe/${subRecipe.recipeid}`}
        margin={{ right: "medium" }}>
        <FormNext />
    </Box>
</ListItem>

export const SubRecipes = ({ subRecipes, addSubRecipe, removeSubRecipe }) => (
    <Box>
        {subRecipes.length === 0 && <Text>Link another recipe to be visible on this page</Text>}
        <Box fill tag="ul">
            {subRecipes.map(r => <SubRecipe
                key={r.recipeid}
                subRecipe={r}
                removeSubRecipe={removeSubRecipe}
            />)}
        </Box>
        <AddSubRecipeComponent
            onSubmit={subRecipe => addSubRecipe(subRecipe)}
        />
    </Box>
)