export class ImageResize {
    // https://hacks.mozilla.org/2011/01/how-to-develop-a-html5-image-uploader/
    resize() {
        const file = document.getElementById('img-file-input').files[0]

        // todo: canvas size?
        const canvas = document.getElementsByTagName('canvas')[0];
        const ctx = canvas.getContext("2d")

        var reader = new FileReader();
        reader.onload = function (event) {
            var img = new Image();
            img.onload = function () {
                var MAX_WIDTH = 800;
                var MAX_HEIGHT = 600;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
            }
            img.src = event.target.result;
        }
        reader.readAsDataURL(file);
    }

    upload(url) {
        return new Promise((resolve, reject) => {
            const canvas = document.getElementsByTagName('canvas')[0];
            canvas.toBlob(blob => {
                const xhr = new XMLHttpRequest();
                xhr.open('put', url);
                xhr.send(blob);
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        resolve()
                    } else {
                        reject({
                            status: xhr.status,
                            response: xhr.response
                        })
                    }
                };

                xhr.onerror = function () { // only triggers if the request couldn't be made at all
                    reject(`Network Error`);
                };
            })
        })
    }
}