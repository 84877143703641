import React from 'react';
import { Box, Button, Form, FormField, Footer, Markdown, StarRating, Text, TextArea } from 'grommet';
import { FormEdit, FormTrash, Star } from 'grommet-icons';
import { EditableBoxComponent, EditButton } from './links';

function AsDate(dateString) {
    return new Date(dateString).toLocaleDateString();
}

export class EditReviewComponent extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    delete() {
        let { removeReview } = this.props;
        removeReview();
    }

    render() {
        let { review, updateReview } = this.props;

        return <Box width="100%" border={{ side: "bottom" }}>
            <EditableBoxComponent
                placeholder="Update link notes"
                value={review.notes}
                update={value => updateReview({ notes: value })}
                multiline={true} />
        </Box>;
    }
}

export class DisplayReview extends React.Component {
    constructor() {
        super();
        this.state = {
            editingReview: false,
        };
    }

    updateReview(update) {
        let { updateReview } = this.props;

        updateReview(update)
        this.setState({ editingReview: false })
    }

    render() {
        let { review, removeReview } = this.props;
        let { editingReview } = this.state;

        return <Box border="top" pad="medium">
            <Box direction="row" justify="between" margin={{ bottom: 'medium' }}>
                <Box direction="row" justify="between" width='fill'>
                    <Text weight='bold'>{AsDate(review.date)}
                        {!!review.addedBy && ` [${review.addedBy}]`}
                    </Text>
                </Box>
                {!!review.rating && <Box direction="row">{Array.from({ length: review.rating }, (_, i) => <Star />)}</Box>}
                {review.canEdit && <Box direction="row" alignSelf="end">
                    <EditButton
                        icon={<FormEdit />}
                        onClick={() => this.setState({ editingReview: !editingReview })} />
                    {editingReview && <EditButton
                        icon={<FormTrash />}
                        onClick={() => removeReview()} />}
                </Box>}
            </Box>
            {editingReview && <EditReviewComponent
                review={review}
                updateReview={(update) => this.updateReview(update)}
                removeReview={() => removeReview()}
            />}
            {!editingReview && <Markdown>{review.notes}</Markdown>}
        </Box>
    }
}

export class AddReview extends React.Component {
    constructor() {
        super();
        this.state = {
        };
    }

    addReview(update) {
        let { addReview } = this.props;

        addReview(update)
    }

    render() {

        return <Form
            onSubmit={({ value }) => this.addReview(value)}
            validate="submit"
        >
            <FormField
                label="Star rating"
                htmlFor="star-rating"
                name="rating"
                pad={{ left: "medium", bottom: "medium" }}
            >
                {/* <Text margin={{bottom: "small"}}>1 = Probs delete. 2 = Needs work. 3 = Tasty, as usual.
                    4 = Nice treat. 5 = I would eat this every day.</Text> */}
                <StarRating id="star-rating" name="rating" />
            </FormField>
            <FormField label="Comments" htmlFor="notes" name="notes">
                <TextArea id="notes" name="notes" />
            </FormField>
            <Footer
                margin={{ top: 'medium', bottom: 'small' }}
            >
                <Button label="Submit Feedback" primary type="submit" />
            </Footer>
        </Form>
    }
}
