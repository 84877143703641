import React from 'react';
import { Box, Select, Text } from 'grommet';
import { FormCheckmark, FormClose } from 'grommet-icons';

export function AddNewTag({ addTag, defaultOptions, onClose }) {

    const [options, setOptions] = React.useState(defaultOptions);
    const [value, setValue] = React.useState();

    return (
        <Box align="center" direction="row">
            <Select
                plain
                valueLabel={
                    <Box
                        direction="row"
                        align="center"
                        round={true}
                        border={{ size: 'medium', color: "accent-4" }}
                        margin="small"
                        pad={{ horizontal: "medium" }}>
                        <Text>{value || 'select...'}</Text>
                    </Box>
                }
                options={options}
                value={value}
                size="small"
                onChange={({ option }) => setValue(option)}
                onSearch={text => {
                    // The line below escapes regular expression special characters:
                    // [ \ ^ $ . | ? * + ( )
                    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

                    // Create the regular expression with modified value which
                    // handles escaping special characters. Without escaping special
                    // characters, errors will appear in the console
                    const exp = new RegExp(escapedText, "i");
                    const filtered = [];
                    let matches = false;
                    defaultOptions.forEach(o => {
                        if (exp.test(o)) {
                            filtered.push(o);
                        }
                        if (o === text) {
                            matches = true;
                        }
                    });
                    if (!matches) {
                        filtered.push(text)
                    }
                    setOptions(filtered);
                }}
            />
            <FormCheckmark onClick={() => addTag(value.toLowerCase())} />
            <FormClose onClick={() => onClose()} />
        </Box>
    );
}
