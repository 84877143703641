import React from 'react';
import { Box, Button } from 'grommet';
import { Upload } from 'grommet-icons';
import { ImageResize, UrlCache, Api } from '../../../services';

const onChange = async (event) => {
    const file = event.target.files[0];
    const image = await new ImageResize().resize(file);
    console.log(image);
    return image
}

export class UploadImageComponent extends React.Component {
    constructor() {
        super()
        this.state = this.startingState();
    }

    startingState() {
        return {
            adding: false
        }
    }

    toggleAdding() {
        this.setState({
            adding: !this.state.adding
        })
    }

    onSelected(ing) {
        if (typeof this.props.onSelected === 'function') {
            this.props.onSelected(ing)
        }
        this.setState(this.startingState())
    }

    async uploadCanvas(ev) {
        const resized = onChange(ev)
        const { recipeId, reloadRecipe } = this.props;

        try {
            const url = await UrlCache.put(recipeId)
            await new ImageResize().upload(url, resized)
            await Api.metadata.updateImage(recipeId, "true")
        }
        catch (err) {
            console.log('error', err)
        }

        this.setState({ uploading: false })
        reloadRecipe()
    }

    render() {
        const {
            uploading,
        } = this.state;

        const buttons = uploading ? [{
            text: 'Upload',
            icon: <Upload />,
            onClick: () => this.uploadCanvas(),
        }, {
            text: 'Cancel',
            icon: <Upload />,
            onClick: () => this.setState({ uploading: false }),
        }] : [{
            text: 'Add image',
            icon: <Upload />,
            onClick: () => this.setState({ uploading: true }),
        }]

        return <Box>
            <Box direction='row'>
                {buttons.map(button => <Button
                    key={button.text}
                    label={button.text} margin="xsmall" alignSelf="start"
                    background="neutral-4"
                    icon={button.icon}
                    onClick={button.onClick} />)}
            </Box>

            {uploading && <>
                <input id="img-file-input" type="file" ref={this.fileInput}
                    onChange={(ev) => onChange(ev)}
                />
                <canvas id="img-canvas" style={{ border: '1px solid' }}></canvas>
            </>}
        </Box>
    }
}
