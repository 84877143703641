import { session } from '../auth';
import axios from 'axios';

export class CancelToken {
    constructor() {
        this.signal = axios.CancelToken.source();
    }

    get token() {
        return this.signal.token;
    }

    cancel() {
        this.signal.cancel();
    }
}

export class HttpRequest {
    constructor(baseUrl, noAuth) {
        if (!baseUrl) {
            console.error('no baseUrl')
        }
        if (baseUrl[baseUrl.length - 1] !== '/') {
            baseUrl = baseUrl + '/'
        }
        this.baseUrl = baseUrl;
        this.noAuth = noAuth;
    }

    async getAuthAsync() {
        try {
            return await session.currentSession();
        } catch (e) {
            return null;
        }
    }

    async get(url, cancelToken) {
        return this.request(url, 'get', null, cancelToken)
    }

    async post(url, data) {
        return this.request(url, 'post', data)
    }

    async put(url, data) {
        return this.request(url, 'put', data)
    }

    async delete(url, data) {
        return this.request(url, 'delete', data)
    }

    async request(url, method, data, cancelToken) {
        if (!this.baseUrl) {
            return new Promise((resolve, reject) => reject('no baseUrl'));
        }
        url = this.baseUrl + url;

        try {
            const session = await this.getAuthAsync();
            const headers = {};
            if (!!session && !this.noAuth) {
                console.log('got session', session)
                const idToken = session.idToken.jwtToken;
                headers['Authorization'] = idToken;
            }

            // todo: add this into request and call from componentWillUnmount
            // https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/
            cancelToken = cancelToken || axios.CancelToken.source();

            const response = await axios({
                method,
                url: url,
                headers,
                data,
                cancelToken: cancelToken.token
            });
            return response.data;
        } catch (error) {
            return new Promise((resolve, reject) => reject('rejected'));
        }
    }
}
