import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { Troubleshoot } from 'grommet-icons';

export class ErrorComponent extends React.Component {

    render() {
        const { error } = this.props;

        return <Box align="center" pad="medium">
            <Heading size="small">
                Error <Troubleshoot />
            </Heading>
            <Text>{error}</Text>
            <Box margin={{ top: '8px' }}><img src="/illiminate-coloured.jpg" style={{ maxWidth: "200px" }} alt="" /></Box>
        </Box>
    }
}
