import React from 'react';
import { Box, FormField, Text } from 'grommet';
import { FormAdd, FormClose } from 'grommet-icons';
import { AddNewTag } from './TagViewComponent';
import { EditLinkComponent, EditableBoxComponent } from './links';

export function EditTags({ tags, suggestedTags, addTag, removeTag }) {

    const [adding, setAdding] = React.useState(false);

    return <FormField name="tags" label="Tags">
        <Box direction="row" align="center" wrap={true}>
            {(tags || []).map(tag => <Box
                key={tag}
                direction="row"
                align="center"
                round={true}
                background="accent-4"
                margin="small"
                pad={{ horizontal: "medium" }}>
                <Text>{tag}</Text>
                <Box onClick={() => removeTag(tag)}><FormClose /></Box>
            </Box>)}
            {!adding && <Box
                background="neutral-4"
                round={true}
                direction="row"
                margin="small"
                pad={{ horizontal: "medium" }}
                onClick={() => setAdding(true)}>
                <Text>Add Tag</Text>
                <FormAdd /></Box>}
            {adding && <AddNewTag
                addTag={(...args) => addTag(...args)}
                onClose={() => setAdding(false)}
                defaultOptions={(suggestedTags || [])} />}
        </Box>
    </FormField>
}

export function EditLinks({ links, addLink, updateLink, removeLink }) {

    const [newLink, setNewLink] = React.useState('');

    return <FormField name="links" label="Links">
        {links && links.map((link, i) => <EditLinkComponent
            key={link.url}
            link={link}
            updateLink={(property, value) => updateLink(i, property, value)}
            removeLink={() => removeLink(i)}
        />)}
        <EditableBoxComponent placeholder="Add New Link URL"
            value={newLink}
            update={event => addLink(event) && setNewLink(newLink + ' ')}
        />
    </FormField>;
}
