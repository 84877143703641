import { Api } from './Api';

// UrlCache caches the presigned urls in memory for 10 mins
class urlCache {

    async get(recipeId) {
        const cacheKey = this._cacheKey('get', recipeId);
        const { found, url, valid } = this._checkCache(cacheKey);
        if (found) {
            return valid ? url : null;
        }
        const response = await Api.image(recipeId, 'GET')
        try {
            const valid = await this._checkUrl(response);
            this._setCache(cacheKey, response, valid);
            return valid ? response : null;
        } catch (ex) {
            console.log('error getting image', ex)
            return null;
        }
    }

    async put(recipeId) {
        this._invalidateCache(recipeId);

        const cacheKey = this._cacheKey('put', recipeId);
        const { found, url } = this._checkCache(cacheKey);
        if (found) {
            return url;
        }
        const response = await Api.image(recipeId, 'PUT')
        this._setCache(cacheKey, response);
        return response;
    }

    _invalidateCache(recipeId) {
        const cacheKey = this._cacheKey('get', recipeId);
        sessionStorage.removeItem(cacheKey);
    }

    _checkUrl(url) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('get', url);
            xhr.send();
            xhr.onload = function () {
                if (xhr.status === 200) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            };

            xhr.onerror = function () { // only triggers if the request couldn't be made at all
                reject(`Network Error`);
            };
        })
    }

    _checkCache(cacheKey) {

        const cached = sessionStorage.getItem(cacheKey);
        if (typeof cached !== 'string') {
            return { found: false }
        }

        const split = cached.split('|');
        const expiry = split[0];
        const url = split[1];
        const valid = split[2];
        // todo: validation?

        if (expiry < Date.now()) {
            sessionStorage.removeItem(cacheKey);
            return { found: false }
        }

        return { found: true, url, valid: valid === 'true' }
    }

    _setCache(cacheKey, url, valid) {
        // expire in 10 minutes
        const expiry = Date.now() + 600000;
        // console.log('expires at', new Date(expiry))
        const value = `${expiry}|${url}|${valid}`
        sessionStorage.setItem(cacheKey, value)
    }

    _cacheKey(verb, recipeId) {
        return `IMAGE_${verb}_${recipeId}`;
    }
}

export const UrlCache = new urlCache();