import React from 'react';
import { RecipeIngredients } from './Ingredients'
import { Method } from './Method'
import { SubRecipes } from './SubRecipes'
import { ViewableByComponent } from './ViewableByComponent'
import { UploadImageComponent } from '../uploadimage'
import { Box, Button, Form, FormField, Heading, Accordion, AccordionPanel } from 'grommet';
import { Bar, Add } from 'grommet-icons';
import { ErrorComponent } from '../../layout';
import { EditLinks, EditTags } from './EditTagsAndLinks';
import { EditableBoxComponent } from './links';
import styled from 'styled-components';

const CenterHeading = styled(Heading)`
  text-align: center;
`;
export class RecipeEditComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoaded: false,
      recipe: null,
      imgUrl: null,
      showSidebar: false,
    };
  }

  toggle(property) {
    const newState = {};
    newState[property] = !this.state[property]
    this.setState(newState);
  }

  onToggleSidebar = () =>
    this.setState({ showSidebar: !this.state.showSidebar });

  onCloseUploadImageModal() {
    const { onFinishUploading, } = this.props
    onFinishUploading();
    this.viewImage();
  }

  render() {
    const {
      loading,
      error,
      recipe,
      suggestedTags,
    } = this.props;

    if (!!error) {
      return <ErrorComponent error={error} />
    }

    if (loading) {
      return <Box align="center" pad="medium">
        <Heading size="small">
          Loading recipe <Bar />
        </Heading>
      </Box >
    }

    const {
      rename,
      updateNotes,
      addMethodStep,
      addMethodSteps,
      updateMethodStep,
      removeMethodStep,
      addStepIngredient,
      updateStepIngredient,
      removeStepIngredient,
      addIngredient,
      addIngredientBatch,
      updateIngredient,
      removeIngredient,
      addSubRecipe,
      removeSubRecipe,
      addTag,
      removeTag,
      updateViewableByEveryone,
      addLink,
      updateLink,
      removeLink,
      reloadRecipe,
      loadFromGousto,
    } = this.props

    const {
    } = this.state;

    const subRecipes = recipe.recipes || [];
    const method = recipe.method || [];
    const empty = method.length === 0 && (recipe.ingredients || []).length === 0

    return <Box
      direction="row"
      background="light-1">
      <Box
        width="100%"
        margin={{ top: 'small' }}>
        <Box>
          <CenterHeading>
            {recipe.name}
          </CenterHeading>
          <Accordion>
            <AccordionPanel label="Name and description">
              <Box pad="medium" background="neutral-5">
                <Form>
                  <FormField name="recipe.name" label="Name">
                    <EditableBoxComponent placeholder="Recipe Name"
                      value={recipe.name}
                      update={name => rename(name)}
                    />
                  </FormField>
                  <FormField name="recipe.notes" label="Description">
                    <EditableBoxComponent placeholder="Set recipe description"
                      value={recipe.notes}
                      multiline={true}
                      update={notes => updateNotes(notes)}
                    />
                  </FormField>
                </Form>
              </Box>
            </AccordionPanel>
            <AccordionPanel label="Links, tags and other metadata">
              <Box pad="medium" background="neutral-5">
                <EditLinks
                  links={recipe.metadata?.recipeLinks ?? []}
                  addLink={(linkUrl) => addLink(linkUrl)}
                  updateLink={(...args) => updateLink(...args)}
                  removeLink={(...args) => removeLink(...args)}
                />
                <EditTags
                  tags={recipe.metadata?.tags ?? []}
                  addTag={addTag}
                  removeTag={removeTag}
                  suggestedTags={suggestedTags}
                />
                <Box>
                </Box>
                {recipe.canEdit && <ViewableByComponent
                  updateViewableByEveryone={(...args) => updateViewableByEveryone(...args)}
                  viewableBy={recipe.metadata?.viewableBy || {}}
                />}
                <UploadImageComponent recipeId={recipe.id} hasImage={recipe.metadata?.image} reloadRecipe={reloadRecipe} />
                {empty && <Button
                  label='Load from gousto'
                  margin="xsmall" alignSelf="start"
                  background="neutral-4"
                  icon={<Add />}
                  onClick={() => loadFromGousto()} />}
              </Box>
            </AccordionPanel>
            <AccordionPanel label="Recipe ingredients">
              <Box pad="medium" background="neutral-5">
                <RecipeIngredients
                  recipeId={recipe.id}
                  ingredients={recipe.ingredients}
                  hasImage={recipe.metadata?.image}
                  addIngredient={(ingredientId) => addIngredient(ingredientId)}
                  addIngredientBatch={(ingredientId) => addIngredientBatch(ingredientId)}
                  updateIngredient={(...args) => updateIngredient(...args)}
                  removeIngredient={(ingredientId) => removeIngredient(ingredientId)}
                />
              </Box>
            </AccordionPanel>
            <AccordionPanel label="Method">
              <Box pad="medium" background="neutral-5">
                <Method
                  recipeId={recipe.id}
                  addMethodStep={() => addMethodStep()}
                  addMethodSteps={(steps) => addMethodSteps(steps)}
                  method={method}
                  updateMethodStep={(stepIndex, updates) => updateMethodStep(stepIndex, updates)}
                  removeMethodStep={stepIndex => removeMethodStep(stepIndex)}
                  addIngredient={(stepIndex, ingredientName) => addStepIngredient(stepIndex, ingredientName)}
                  updateIngredient={(...args) => updateStepIngredient(...args)}
                  removeIngredient={(stepIndex, ingredientId) => removeStepIngredient(stepIndex, ingredientId)}
                />
              </Box>
            </AccordionPanel>
            <AccordionPanel label="Subrecipes">
              <Box pad="medium" background="neutral-5">
                <SubRecipes
                  subRecipes={subRecipes}
                  addSubRecipe={subRecipe => addSubRecipe(subRecipe)}
                  removeSubRecipe={subRecipe => removeSubRecipe(subRecipe)}
                />
              </Box>
            </AccordionPanel>
          </Accordion>
        </Box>
      </Box>
    </Box>
  }
}
