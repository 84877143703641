import React from 'react';
import { Api } from '../../../services';
import styled from 'styled-components';
import { Box, Text } from 'grommet';
import { EditableTextBox } from '../../layout';

const DigitalText = styled(Text)`
  font-family: 'Turret Road', cursive;
`;

const ListItem = props => <Box
    tag="li"
    direction="row"
    justify="between"
    pad={{ left: "large", vertical: "medium" }}
    border={{ size: "small", side: "bottom" }}
    {...props} />;

class Suggestions extends React.Component {

    render() {
        const {
            showSuggestions,
            suggestions,
            onSuggestionSelected } = this.props;

        return (
            <Box fill tag="ul">
                {showSuggestions && suggestions.map(suggestion => <ListItem
                    key={suggestion.id}
                    onClick={() => onSuggestionSelected(suggestion)}
                >
                    <DigitalText>{suggestion.name}</DigitalText>
                </ListItem>)}
            </Box>
        )
    }
}

export class IngredientSearchComponent extends React.Component {
    constructor() {
        super()
        this.state = this.startingState();
    }

    searchTimeout;

    startingState() {
        return {
            value: '',
            showSuggestions: false,
            gotSearchResults: false,
            searchResults: [],
        }
    }

    handleChange(value) {

        this.searchAfterTimeout(value);

        this.setState({
            showSuggestions: false,
        })
    }

    searchAfterTimeout(value) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.handleSubmit(value)
        }, 300);
    }

    handleSubmit(value) {
        if (value.length > 0) {
            Api.searchIngredients(value)
                .then(({ ingredients }) => this.setState({
                    showSuggestions: true,
                    gotSearchResults: true,
                    searchResults: ingredients
                }))
                .catch(err => console.error(err));
        }
    }

    onSuggestionSelected(ingredient) {
        this.props.onSelected(ingredient.name);
    }

    render() {
        const {
            onCancel,
            onSelected,
        } = this.props;
        const {
            showSuggestions,
            searchResults,
            value
        } = this.state;

        const suggestions = (searchResults || []);

        return <Box fill pad="small" width="100%" margin={{ horizontal: "medium" }}>
            <EditableTextBox
                onCancel={() => onCancel()}
                onSubmit={(ingName) => onSelected(ingName)}
                onChange={event => this.handleChange(event)}
                placeholder='New ingredient name'
            />

            <Suggestions
                showSuggestions={showSuggestions}
                searchTerm={value}
                suggestions={suggestions}
                onSuggestionSelected={ing => this.onSuggestionSelected(ing)} />
        </Box>
    }
}
