import React from 'react';
import { Box } from 'grommet';
import { FormAdd } from 'grommet-icons';
import { IngredientSearchComponent } from '../ingredientsearch';
import { EditableBoxComponent } from '../../layout';
import { Api } from '../../../services';

export class AddIngredientComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            addingBatch: false,
            editing: false,
            batchValue: '',
        };
    }

    toggle(property) {
        const newState = {};
        newState[property] = !this.state[property]
        this.setState(newState);
    }

    async detextText() {
        const { recipeId } = this.props
        const response = await Api.imageDetectText(recipeId)
        // // // localStorage.setItem('detected', JSON.stringify(response))
        // const response = JSON.parse(localStorage.getItem('detected'))
        const batchValue = response.Blocks
            .map(t => t.Text || "")
            .join('\n')

        this.setState({ addingBatch: true, batchValue })
    }

    complete() {
        this.setState({ editing: false, addingBatch: false })
    }

    render() {
        const { addIngredient, addIngredientBatch, hasImage } = this.props;
        const { editing, addingBatch, batchValue } = this.state

        return <Box width="100%">
            {editing && <IngredientSearchComponent
                onSelected={(ing) => addIngredient(ing) && this.complete()}
                onCancel={() => this.toggle('editing')}
            />}
            {addingBatch && <Box margin={{ top: 'medium' }}>
                <EditableBoxComponent
                    placeholder='Copy ingredients'
                    multiline={true}
                    value={batchValue}
                    update={value => addIngredientBatch(value) && this.complete()}
                /></Box>}
            {!editing && !addingBatch && <Box direction="row">
                <Box margin="medium" direction="row" onClick={() => this.toggle('editing')}>
                    Add new<FormAdd />
                </Box>
                <Box margin="medium" direction="row" onClick={() => this.toggle('addingBatch')}>
                    Add all from recipe<FormAdd />
                </Box>
                {hasImage && <Box margin="medium" direction="row" onClick={() => this.detextText()}>
                    Detect from image <FormAdd />
                </Box>}
            </Box>}
        </Box>
    }
}
