import React from 'react';
import { session } from '../../auth';
import { Box, Button, Heading } from 'grommet';
import { Login, Logout } from 'grommet-icons';

import styled from 'styled-components';

const CenterHeading = styled(Heading)`
  text-align: center;
`;

export class UserInfo extends React.Component {

    constructor() {
        super();
        this.state = {
            error: null,
            isLoaded: false,
        }
    }

    componentDidMount() {
        session.onUserUpdated(user => this.setState({ user, isLoaded: true }));
    }

    render() {
        const { user } = this.state;

        if (!!user) {
            const { attributes: { nickname } } = this.state.user;
            return <Box>
                <CenterHeading size="2">Welcome {nickname}!</CenterHeading>
                <Button
                    icon={<Logout />}
                    label="Sign Out"
                    margin="small"
                    onClick={() => session.signOut()}
                    plain
                />
            </Box>
        } else {
            return <Button
                icon={<Login />}
                label="Sign In"
                margin="small"
                onClick={() => session.hostedUI()}
                plain
            />
        }
    }
}