import React from 'react';
import { Box, Button, Text } from 'grommet';
import styled from 'styled-components';

import { Api, CancelToken } from '../../../../services';
import { ResponsiveListGrid } from '../../../layout'
import { DigitalHeading } from '../../styled';

const RelativeBox = styled(Box)`
  position: relative;
`;

export class ViewRecipesComponent extends React.Component {

    constructor() {
        super();
        this.state = {
            error: null,
            isLoaded: false,
            userviews: []
        };
    }

    componentDidMount() {
        this.tryLoadRecipes();
    }

    async tryLoadRecipes() {
        this.cancelToken = new CancelToken();

        try {
            const publicRecipesResult = await Api.public.recipes(this.cancelToken)

            const userviews = publicRecipesResult.users
            this.setState({
                isLoaded: true,
                userviews
            });
        } catch (e) {
            if (!this._isCanceled) {
                console.error('error requesting recipes', e)
            }
        }
    }

    componentWillUnmount() {
        this._isCanceled = true;
        this.cancelToken.cancel(e => console.log(e));
    }

    render() {
        const { isLoaded, userviews } = this.state;

        const loadUser = (uv) => {
            if (!!uv.nickname) return true
            if (!!uv.recipes && uv.recipes.length > 0) return true
            return false
        }

        return <ResponsiveListGrid>
            {isLoaded && userviews.map(userview =>
                <>
                    {loadUser(userview) && <Box
                        key={userview.id}
                        margin="medium" pad="medium" background='light-1'>
                        <DigitalHeading margin="medium">{userview.nickname}</DigitalHeading>
                        {userview.recipes.map((r, idx) => {
                            return <Box key={r.id} align="center" pad="medium">
                                <Button
                                    href={`/public/recipe/${r.id}`}
                                    pad="medium"
                                > <RelativeBox margin="0">
                                        <Text weight="bold">{r.name}</Text>
                                    </RelativeBox>
                                </Button>
                            </Box>
                        })}
                        {userview.recipes.length === 0 && <Box align="center" pad="medium">
                            <RelativeBox margin="0">
                                <Text weight="bold">No recipes</Text>
                            </RelativeBox>
                        </Box>}
                    </Box>}
                </>
            )}
        </ResponsiveListGrid>
    }
}