import React from 'react';
import { Box, Button, Text } from 'grommet';
import { FormCheckmark, FormClose, FormTrash } from 'grommet-icons';
import { Ingredients } from './Ingredients';
import { SubHeading } from '../styled';
import { ResizableTextArea } from '../../layout';
import { AddIngredientComponent } from './AddIngredientComponent';

export class EditMethodStepDescription extends React.Component {
    constructor() {
        super()
        this.state = this.startingState();
    }

    startingState() {
        return {
            descriptionUpdate: undefined,
            edited: false,
        };
    }

    handleChange(event) {
        const value = event.target.value;

        this.setState({
            descriptionUpdate: value,
            edited: true,
        })
    }

    updateMethodStep() {
        const { updateMethodStep, stepIndex } = this.props;
        const { descriptionUpdate, edited } = this.state;

        if (edited
            && typeof updateMethodStep === 'function') {
            updateMethodStep(stepIndex, { description: descriptionUpdate, action: '', time: '' });
        }

        this.setState(this.startingState())
    }

    render() {
        const { methodStep } = this.props;
        const { descriptionUpdate, edited } = this.state;

        const originalDescription = [methodStep.action, methodStep.time, methodStep.description]
            .filter(x => !!x).join('\n\n')

        const editableDescription = edited ? descriptionUpdate : originalDescription

        const changed = edited && descriptionUpdate !== originalDescription

        const descriptionOptions = <Box direction="row">{changed && [{
            icon: <FormCheckmark />,
            onClick: () => { this.updateMethodStep(); }
        }, {
            icon: <FormClose />,
            onClick: () => this.setState(this.startingState())
        }].map(({ icon, onClick }, idx) => <Box
            hoverIndicator
            key={idx}
            onClick={onClick}
            margin={{ horizontal: "xsmall" }}
            background="accent-2">
            {icon}
        </Box>)}</Box>

        return <Box>
            <Box alignSelf="end">{descriptionOptions}</Box>
            <Box direction='column'
                margin={{ left: 'medium' }}
                justify="between">
                <Box width="100%" border={{ side: "bottom" }}>
                    <ResizableTextArea
                        plain
                        placeholder={'Edit description'}
                        size='medium'
                        value={editableDescription}
                        onChange={event => this.handleChange(event)}
                    />
                </Box>
            </Box>
        </Box>
    }
}

export class EditMethodStep extends React.Component {
    constructor() {
        super()
        this.state = {
            confirmingRemove: false,
        };
    }

    toggle(property) {
        const newState = {};
        newState[property] = !this.state[property]
        this.setState(newState);
    }

    render() {
        const {
            methodStep,
            onClose,
            stepIndex,
            addIngredient,
            updateIngredient,
            removeIngredient,
            updateMethodStep,
            removeMethodStep } = this.props;
        const { confirmingRemove } = this.state

        const options = (confirmingRemove
            ? [{
                label: 'Yes',
                icon: <FormCheckmark />,
                onClick: () => removeMethodStep(stepIndex)
            }, {
                label: 'No',
                icon: <FormClose />,
                onClick: () => this.setState({ confirmingRemove: false })
            }]
            : [{
                label: 'Close',
                icon: <FormClose />,
                onClick: () => onClose()
            }, {
                label: 'Remove',
                icon: <FormTrash />,
                onClick: () => this.setState({ confirmingRemove: true })
            }]).map(({ icon, label, onClick }, idx) =>
                <Button
                    key={idx}
                    label={label}
                    margin="xsmall"
                    alignSelf="end"
                    color="accent-2"
                    icon={icon}
                    onClick={onClick} />
            );

        return <Box
            background="accent-2"
            margin={{ bottom: 'medium' }}
            pad={{ left: 'medium' }}>
            {confirmingRemove
                ? <Box>
                    <SubHeading>Removing Method Step</SubHeading>
                    <Box>
                        <Text>Are you sure?</Text>
                        <Box direction="row" justify="around">{options}</Box>
                    </Box>
                </Box>
                : <Box>
                    <Box direction="row" justify="between" wrap>
                        <SubHeading>Editing Method Step</SubHeading>
                        <Box alignSelf="end">{options}</Box>
                    </Box>
                    <EditMethodStepDescription
                        methodStep={methodStep}
                        stepIndex={stepIndex}
                        updateMethodStep={updateMethodStep} />
                    <Box fill>
                        <SubHeading>Ingredients</SubHeading>
                        <Ingredients
                            editing={true}
                            removeIngredient={ing => removeIngredient(stepIndex, ing)}
                            updateIngredient={(...args) => updateIngredient(stepIndex, ...args)}
                            ingredients={methodStep.ingredients || []}
                        />
                        <Box direction="row" tag="li" pad="small">
                            <AddIngredientComponent
                                addIngredient={(ingredientName) => addIngredient(stepIndex, ingredientName)}
                            />
                        </Box>
                    </Box>
                </Box>
            }
        </Box>
    }
}
