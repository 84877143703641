import React from 'react';
import { Box, Button, FormField, Heading, Select, SelectMultiple, Text, TextInput } from 'grommet';
import { Cafeteria, Search } from 'grommet-icons';
import { Api } from '../../../services/Api';

import styled from 'styled-components';

const CenterHeading = styled(Heading)`
  text-align: center;
`;

const sortOptions = [{
    label: 'Name A-Z',
    value: 'name'
}, {
    label: 'Name Z-A',
    value: '-name'
}, {
    label: 'Added Latest',
    value: '-addedon'
}, {
    label: 'Recently viewed',
    value: '-metadata.lastviewed'
}]

const limitOptions = [{
    label: '10',
    value: '10'
}, {
    label: '25',
    value: '25'
}, {
    label: '50',
    value: '50'
}, {
    label: '100',
    value: '100'
}]

export class SearchRecipesComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            error: null,
            value: '',
            selectedSort: '',
            selectedLimit: '10',
            searching: false,
            customTags: [],
            suggestedTags: [],
            tags: [],
        };
    }

    componentDidMount() {
        Api.tags()
            .then(({ tags }) => {
                this.setState({ suggestedTags: tags })
            })
            .catch(r => console.log(r));

        // this.updateTags(['favourite'])
        this.updateSort(sortOptions[3])
    }

    handleChange(event) {
        const value = event.target.value;

        this.searchAfterTimeout(value);

        this.setState({ value, })
    }

    searchAfterTimeout(value) {
        clearTimeout(this.searchTimeout);

        const { selectedSort, selectedLimit, tags } = this.state;
        this.searchTimeout = setTimeout(() => {
            this.search(value, selectedSort, selectedLimit, tags)
        }, 300);
    }

    addTag(tag) {
        const { tags } = this.state;
        if (!tags.find(t => t === tag)) {
            this.updateTags([...tags, tag])
        }
    }

    removeTag(tag) {
        const { tags } = this.state;
        this.updateTags(tags.filter(t => t !== tag))
    }

    updateSort(selectedSort) {
        const { value, selectedLimit, tags } = this.state
        this.search(value, selectedSort, selectedLimit, tags)
    }

    updateLimit(selectedLimitOption) {
        const { value, selectedSort, tags } = this.state
        const selectedLimit = selectedLimitOption?.value
        this.search(value, selectedSort, selectedLimit, tags)
    }

    updateTags(tags) {
        const { selectedSort, selectedLimit, value } = this.state
        this.search(value, selectedSort, selectedLimit, tags)
    }

    selectMultipleChange(optionsFromSelect, tag) {
        if (!!tag) {
            if (!!optionsFromSelect.find(x => x === tag)) {
                this.addTag(tag)
            } else {
                const { customTags, filteredTags } = this.state
                if (!!filteredTags && !!filteredTags.find(x => x === tag)) {
                    this.addTag(tag)
                    this.setState({ customTags: [...customTags, tag] })
                } else {
                    this.removeTag(tag)
                }
            }
        }

        this.setState({ filteredTags: null })
    }

    searchTags(s) {
        const { suggestedTags } = this.state

        const filtered = suggestedTags.filter(x => x.startsWith(s))
        this.setState({ filteredTags: [s, ...filtered] })
    }

    search(value, selectedSort, selectedLimit, tags) {
        Api.recipes(value, selectedSort?.value || '', tags, undefined, selectedLimit)
            .then(({ recipes, total }) => {
                this.setState({ recipes, totalRecipes: total })
            })
            .catch(r => console.log(r));

        this.setState({ selectedSort, selectedLimit, tags, value })
    }

    render() {
        const {
            recipes,
            totalRecipes,
            selectedSort,
            selectedLimit,
            filteredTags,
            customTags,
            searching,
            suggestedTags,
            tags,
            value } = this.state;

        const selectTags = filteredTags || [...suggestedTags, ...customTags]
        const notShownRecipes = !!recipes && !!totalRecipes ? totalRecipes - recipes.length : 0

        return <Box pad="medium" background="light-4">
            <CenterHeading>Your Recipes</CenterHeading>
            <Box>
                <Button
                    style={{ width: 'max-content' }}
                    icon={<Search />}
                    alignSelf="end"
                    margin="small"
                    onClick={() => {
                        const { searching } = this.state
                        this.setState({ searching: !searching })
                    }}
                    plain
                />
            </Box>
            {!searching && <Box>
                {value && <Box>
                    Search Text: {value}
                </Box>}
                {tags && tags.length > 0 && <Box>
                    Selected Tags: {tags.join(', ')}
                </Box>}
                {selectedSort && <Box>
                    Sorted by: {selectedSort.label}
                </Box>}
            </Box>}
            {searching && <Box>
                <FormField label="Search">
                    <TextInput
                        placeholder="Search for recipe"
                        value={value}
                        onChange={event => this.handleChange(event)}
                    />
                </FormField>
                <FormField label="Tags">
                    <SelectMultiple
                        placeholder="Select"
                        options={selectTags}
                        value={tags}
                        showSelectedInline={true}
                        onChange={({ value, option }) => this.selectMultipleChange(value, option)}
                        onSearch={x => this.searchTags(x)}
                    />
                </FormField>
                <FormField label="Sort">
                    <Select
                        options={sortOptions}
                        labelKey='label'
                        value={selectedSort}
                        clear={true}
                        onChange={({ option }) => this.updateSort(option)}
                    />
                </FormField>
                <FormField label="Limit">
                    <Select
                        options={limitOptions}
                        labelKey='label'
                        value={selectedLimit}
                        clear={false}
                        onChange={({ option }) => this.updateLimit(option)}
                    />
                </FormField>
            </Box>}

            {!!recipes && <Box>
                {recipes.length > 0 && <Box fill>
                    {recipes.map((recipe, idx) =>
                        <Button key={recipe.id} href={`/recipe/${recipe.id}`}>
                            <Box direction='row' margin='small' align='center'>
                                <Cafeteria />
                                <Box pad={{ left: 'small' }}><Text>{recipe.name}</Text></Box>
                            </Box>
                        </Button>
                    )}
                </Box>}
                {recipes.length === 0 && <Text>No recipes</Text>}
                {notShownRecipes > 0 && <Box fill alignContent='right'>
                    <Box align="center" pad="small">
                        <Text>And {notShownRecipes} more</Text>
                    </Box>
                </Box>}
            </Box>}
        </Box>
    }
}
