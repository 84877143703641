import React from 'react';
import { Box } from 'grommet';

export function EditButton({ onClick, icon }) {
    return <Box
        hoverIndicator
        onClick={onClick}
        margin={{ horizontal: "xsmall" }}
        background="accent-2">
        {icon}
    </Box>;
}
