import { HttpRequest } from './httprequest';

class ApiRequest extends HttpRequest {
    constructor() {
        super('https://wou49isuhj.execute-api.eu-west-2.amazonaws.com/Prod')
    }
}

const Public = {
    recipe(recipeID, cancelToken) {
        return new ApiRequest().get('public/recipe/' + recipeID, cancelToken);
    },

    recipes(cancelToken) {
        return new ApiRequest().get('public/recipes', cancelToken);
    }
}

const UpdateMetadata = {
    rename(recipeID, name) {
        const updates = {
            name
        }
        return this._updateRecipe(recipeID, updates)
    },

    updateNotes(recipeID, notes) {
        const updates = {
            notes
        }
        return this._updateRecipe(recipeID, updates)
    },

    updateLastViewed(recipeID) {
        const updates = {
            set_last_viewed: 'true'
        }
        return this._updateRecipe(recipeID, updates)
    },

    updateImage(recipeID, hasImage) {
        const updates = {
            image: hasImage
        }
        return this._updateRecipe(recipeID, updates)
    },

    addTag(recipeID, tag) {
        const updates = {
            tag_add: tag
        }
        return this._updateRecipe(recipeID, updates)
    },

    removeTag(recipeID, tag) {
        const updates = {
            tag_remove: tag
        }
        return this._updateRecipe(recipeID, updates)
    },

    addLink(recipeID, link) {
        const updates = {
            link_add: link
        }
        return this._updateRecipe(recipeID, updates)
    },

    updateLink(recipeID, linkIdx, type, value) {
        const updates = {
            link_update_linkidx: linkIdx.toString()
        }
        updates[`link_update_${type}`] = value
        return this._updateRecipe(recipeID, updates)
    },

    removeLink(recipeID, linkIdx) {
        const updates = {
            link_remove: linkIdx + ''
        }
        return this._updateRecipe(recipeID, updates)
    },

    viewableByEveryone(recipeID, value) {
        const updates = {
            viewableby_everyone: value.toString()
        }
        return this._updateRecipe(recipeID, updates)
    },

    toggleRecordChanges(recipeID, shouldRecord) {
        const updates = {
            record_changes: shouldRecord.toString()
        }
        return this._updateRecipe(recipeID, updates)
    },

    _updateRecipe(recipeID, updates) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            updates,
            updateType: 'R_UPDATE'
        });
    }
}

export const Api = {

    /**
     * @param {string} name - The name of the new recipe.
     * @return {PromiseLike<?>} - Returns the recipe.
     */
    create(name) {
        return new ApiRequest().put('recipe', {
            name,
            collection: 'public'
        });
    },

    /**
     * @param {string} id - The recipe ID to delete.
     */
    removeRecipe(id) {
        return new ApiRequest().delete(`recipe/${id}`);
    },

    /**
     * @return {PromiseLike<?>} - Returns a map of categories, with recipes.
     */
    recipes(query, sort, tags, notTags, limit) {
        let url = 'recipes';
        const pathParams = []
        if (!!query) {
            pathParams.push(`q=${query}`);
        }
        if (!!tags && tags.length > 0) {
            pathParams.push(`tags=${tags}`);
        }
        if (!!notTags && notTags.length > 0) {
            pathParams.push(`notTags=${notTags}`);
        }
        if (!!sort) {
            pathParams.push(`sort=${sort}`)
        }
        if (!!limit) {
            pathParams.push(`limit=${limit}`)
        }
        if (pathParams.length > 0) {
            url += '?' + pathParams.join('&');
        }
        return new ApiRequest().get(url);
    },

    /**
     * @param {string} recipeID - The recipe ID to get.
     * @return {PromiseLike<?>} - Returns the recipe.
     */
    recipe(recipeID, cancelToken) {
        return new ApiRequest().get('recipe/' + recipeID, cancelToken);
    },

    /**
     * @param {string} recipeID - The id of the recipe to add the method to.
     * @return {PromiseLike<?>} - Returns the updated recipe.
     */
    addMethodStep(recipeID, description) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            updateType: 'STEP_ADD',
            updates: {
                description,
            }
        });
    },

    addMethodSteps(recipeID, steps) {
        const updates = {}
        steps.forEach((s, i) => updates[`method_${i}`] = s)
        return new ApiRequest().post(`recipe/${recipeID}`, {
            updateType: 'STEPS_ADD',
            updates,
        });
    },

    /**
     * @param {string} recipeID - The id of the recipe to update.
     * @param {number} methodStepIndex - The index of the step to update.
     * @param {{ [key: string]: string }} updates - Properties to update
     * @return {PromiseLike<?>} - Returns the updated recipe.
     */
    updateMethodStep(recipeID, methodStepIndex, updates) {
        updates.methodStepIndex = methodStepIndex
        return new ApiRequest().post(`recipe/${recipeID}`, {
            updates,
            updateType: 'STEP_UPDATE'
        });
    },

    /**
     * @param {string} recipeID - The id of the recipe to remove the method from.
     * @param {string} methodStepIndex - The index to remove.
     * @return {PromiseLike<?>} - Returns the updated recipe.
     */
    removeMethodStep(recipeID, methodStepIndex) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            updates: { methodStepIndex },
            updateType: 'STEP_DELETE'
        });
    },

    metadata: UpdateMetadata,

    public: Public,

    /**
     * @param {string} recipeID - The id of the recipe to add the ingredient to.
     * @param {number} methodStepIndex - The index of the step to add to.
     * @param {string} ingredientID - The id of the ingredient to add.
     * @return {PromiseLike<?>} - Returns the updated recipe.
     */
    addStepIngredient(recipeID, methodStepIndex, ingredientName) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            recipeID,
            updates: {
                ingredientName,
                methodStepIndex,
            },
            updateType: 'STEP_ING_ADD'
        });
    },

    /**
     * @param {string} recipeID - The id of the recipe to add the ingredient to.
     * @param {number} methodStepIndex - The index of the step to add to.
     * @param {string} ingredientID - The id of the ingredient to add.
     * @param {{ [key: string]: string }} updates - Properties to update
     * @return {PromiseLike<?>} - Returns the updated recipe.
     */
    updateStepIngredient(recipeID, methodStepIndex, ingredientId, updates) {
        updates.methodStepIndex = methodStepIndex
        updates.ingredientId = ingredientId
        return new ApiRequest().post(`recipe/${recipeID}`, {
            updates,
            updateType: 'STEP_ING_UPDATE'
        });
    },

    /**
     * @param {string} recipeID - The id of the recipe to remove the ingredient from..
     * @param {number} methodStepIndex - The index of the step to remove from.
     * @param {string} ingredientID - The id of the ingredient to remove.
     * @return {PromiseLike<?>} - Returns the updated recipe.
     */
    removeStepIngredient(recipeID, methodStepIndex, ingredientId) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            recipeID,
            updates: {
                methodStepIndex,
                ingredientId,
            },
            updateType: 'STEP_ING_DELETE'
        });
    },

    /**
     * @param {string} recipeID - The id of the recipe to remove the ingredient from..
     * @param {string} ingredientID - The id of the ingredient to remove.
     * @return {PromiseLike<?>} - Returns the updated recipe.
     */
    addIngredient(recipeID, ingredientName) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            recipeID,
            updates: {
                ingredientName,
            },
            updateType: 'ING_ADD'
        });
    },

    addIngredientBatch(recipeID, input) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            recipeID,
            updates: {
                input,
            },
            updateType: 'ING_BATCH_ADD'
        });
    },

    /**
       * @param {string} recipeID - The id of the recipe to add the ingredient to.
       * @param {string} ingredientID - The id of the ingredient to add.
       * @param {{ [key: string]: string }} updates - Properties to update
       * @return {PromiseLike<?>} - Returns the updated recipe.
       */
    updateIngredient(recipeID, ingredientId, updates) {
        updates.ingredientId = ingredientId
        return new ApiRequest().post(`recipe/${recipeID}`, {
            recipeID,
            updates,
            updateType: 'ING_UPDATE'
        });
    },

    /**
     * @param {string} recipeID - The id of the recipe to remove the ingredient from..
     * @param {string} ingredientID - The id of the ingredient to remove.
     * @return {PromiseLike<?>} - Returns the updated recipe.
     */
    removeIngredient(recipeID, ingredientId) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            recipeID,
            updates: {
                ingredientId,
            },
            updateType: 'ING_DELETE'
        });
    },

    /**
     * @param {string} recipeID - The id of the recipe to add the ingredient to.
     * @param {number} subRecipeId - The id of the recipe to add as sub recipe.
     * @return {PromiseLike<?>} - Returns the updated recipe.
     */
    addSubRecipe(recipeID, subRecipeId) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            recipeID,
            updates: {
                subRecipeId,
            },
            updateType: 'SUB_ADD'
        });
    },

    /**
     * @param {string} recipeID - The id of the recipe to add the ingredient to.
     * @param {number} subRecipeID - The id of the recipe to add as sub recipe.
     * @return {PromiseLike<?>} - Returns the updated recipe.
     */
    removeSubRecipe(recipeID, subRecipeId) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            recipeID,
            updates: {
                subRecipeId,
            },
            updateType: 'SUB_DELETE'
        });
    },

    loadFromGousto(recipeID) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            recipeID,
            updateType: 'READ_FROM_GOUSTO'
        });
    },

    /**
     * @param {string}  [text] - Options query string for search.
     * @return {PromiseLike<[]>} - Returns an array of search results
     */
    searchIngredients(text) {
        let q = '';
        if (!!text) {
            q = '?q=' + text;
        }
        return new ApiRequest().get('ingredients' + q);
    },

    /**
     * @param {string} name - The name of the new ingredient.
     * @return {PromiseLike<?>} - Returns the ingredient.
     */
    createNewIngredient(name) {
        return new ApiRequest().put('ingredient', {
            name
        });
    },

    getUserview(cancelToken) {
        return new ApiRequest().get('userview', cancelToken);
    },

    updateUserview(updates) {
        return new ApiRequest().post('userview', updates);
    },

    tags(cancelToken) {
        return new ApiRequest().get('userview/tags', cancelToken);
    },

    addReview(recipeID, updates) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            updateType: 'REVIEW_ADD',
            updates,
        });
    },

    updateReview(recipeID, reviewIndex, review) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            updateType: 'REVIEW_UPDATE',
            updates: {
                reviewIndex,
                ...review,
            }
        });
    },

    removeReview(recipeID, reviewIndex) {
        return new ApiRequest().post(`recipe/${recipeID}`, {
            updateType: 'REVIEW_DELETE',
            updates: {
                reviewIndex,
            }
        });
    },

    /**
     * @return {PromiseLike<?>} - Returns a presigned url.
     */
    image(recipeID, verb) {
        return new ApiRequest().get(`recipe/${recipeID}/image?verb=${verb}`);
    },

    imageDetectText(recipeID,) {
        return new ApiRequest().get(`recipe/${recipeID}/image/detect-text`);
    },
}
