import React from 'react';
import { Api, CancelToken, UrlCache } from '../../../services';
import { RecipeComponent } from './RecipeComponent';

export class PublicRecipePageComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      error: null,
      recipe: null,
      editing: false,
      viewIngredients: false,
      imgUrl: null,
      confirmingRemove: false,
    };
  }

  componentDidMount() {
    this.cancelToken = new CancelToken();
  }

  componentWillUnmount() {
    this._isCanceled = true;
    this.cancelToken.cancel();
    console.log('unmount')
  }

  loadRecipe() {
    const id = this.props.recipeId;

    Api.public.recipe(id, this.cancelToken)
      .then(recipe => {
        this.setRecipe(recipe);
      })
      .catch(_ => {
        if (!this._isCanceled) {
          this.setState({
            recipe: null,
            loadedRecipeId: id,
            error: 'Recipe not found'
          })
        }
      });
  }

  viewImage() {
    const recipe = this.state.recipe;
    if (!recipe) {
      return;
    }

    if (recipe.metadata.image) {
      UrlCache.get(recipe.id, this.cancelToken)
        .then(imgUrl => {
          this.setState({ imgUrl })
        })
        .catch(url => console.log(url))
    }
  }

  setRecipe(recipe, other) {
    this.setState({
      loadedRecipeId: recipe.id,
      recipe,
      ...other
    });
    this.viewImage();
  }

  toggle(property) {
    const newState = {};
    newState[property] = !this.state[property]
    this.setState(newState);
  }

  render() {
    const { recipeId } = this.props;
    const {
      loadedRecipeId,
      recipe,
      error } = this.state;

    if (recipeId !== loadedRecipeId) {
      this.loadRecipe()
    }

    return <RecipeComponent
      loading={recipe === null}
      error={error}
      recipe={recipe}
      suggestedTags={[]}
    />
  }
}
