import React from 'react';
import styled from 'styled-components';
import { Box, Markdown, Text } from 'grommet';
import { FormEdit } from 'grommet-icons';
import AddButton from './AddButton';
import { EditMethodStep } from './EditMethod';
import { EditableBoxComponent } from '../../layout';
const DigitalText = styled(Text)`
  font-family: 'Turret Road', cursive;
`;

function startsWithCapitalLetter(line) {
    return /^[A-Z]/.exec(line)
}

function reconstructText(blocks) {

    const sorted = blocks.sort((b1, b2) => {
        return b1.Geometry.BoundingBox.Top - b2.Geometry.BoundingBox.Top
    });

    return sorted.filter(b => b.BlockType === 'LINE')
}

function reconstructGoustoMethod(response) {

    const batchLeft = reconstructText(response.Blocks.filter(b => b.Geometry.BoundingBox.Left <= 0.5))
    const batchRight = reconstructText(response.Blocks.filter(b => b.Geometry.BoundingBox.Left > 0.5))
    const batchValue = [...batchLeft, ...batchRight]
        .map(t => t.Text || "")
        .join('\n')
    return batchValue
}

const ListItem = props => <Box tag="li" pad="small" {...props} />;

export class MethodStep extends React.Component {
    constructor() {
        super()
        this.state = this.startingState();
    }

    startingState() {
        return {
            editingThisStep: false,
            updates: {}
        };
    }

    editMethodStep() {
        this.setState({ editingThisStep: true })
    }

    resetState() {
        this.setState(this.startingState());
    }

    render() {
        const {
            methodStep,
            stepIndex,
            updateMethodStep,
            removeMethodStep,
            addIngredient,
            updateIngredient,
            removeIngredient } = this.props;
        const { editingThisStep } = this.state

        if (editingThisStep) {
            return <EditMethodStep
                onClose={() => this.setState({ editingThisStep: false })}
                methodStep={methodStep}
                stepIndex={stepIndex}
                updateMethodStep={updateMethodStep}
                removeMethodStep={removeMethodStep}
                addIngredient={addIngredient}
                updateIngredient={updateIngredient}
                removeIngredient={removeIngredient}
            />
        }

        const description = [methodStep.action, methodStep.time, methodStep.description]
            .filter(x => !!x).join('<br/>') || '_No description. Click pencil to edit._'

        const action = `**${stepIndex + 1}.** ${description}`

        return <Box fill>
            <Box direction="row" justify="end">
                <Box
                    hoverIndicator
                    onClick={() => this.editMethodStep()}
                    margin={{ horizontal: "xsmall" }}
                    background="accent-2">
                    <FormEdit />
                </Box>
            </Box>
            <Box fill>
                <Box fill direction="row">
                    <Markdown style={{ maxWidth: 'none' }}>{action}</Markdown>
                </Box>
            </Box>

            {!!methodStep.ingredients && methodStep.ingredients.length > 0 &&
                <Box fill tag="ul">
                    {methodStep.ingredients.map((ing, idx) => <ListItem key={idx}>
                        <Box direction="row">
                            {!!ing.amount && <DigitalText margin={{ horizontal: "small" }}>{ing.amount}</DigitalText>}
                            <DigitalText margin={{ horizontal: "small" }}>{ing.name}</DigitalText>
                        </Box>
                        {!!ing.preparation && <Box
                            pad="small"
                            margin={{ left: "large" }}
                            direction="row">
                            <DigitalText
                                color="neutral-2"
                                margin={{ horizontal: "small" }}>
                                {ing.preparation}</DigitalText>
                        </Box>}
                    </ListItem>)}
                </Box>}
        </Box>
    }
}

export class Method extends React.Component {
    constructor() {
        super();
        this.state = {
            editingMethods: false
        }
    }

    toggle(property) {
        const newState = {};
        newState[property] = !this.state[property]
        this.setState(newState);
    }

    async detextText() {
        const { recipeId } = this.props
        // const response = await Api.imageDetectText(recipeId)
        // // // localStorage.setItem('detected', JSON.stringify(response))
        const response = JSON.parse(localStorage.getItem('detected'))
        const parsedMethod = reconstructGoustoMethod(response)

        this.setState({ addingFromImage: true, parsedMethod })
    }

    tidyGousto() {
        const { parsedMethod } = this.state

        const lines = parsedMethod.split('\n')

        const stepLines = [[]]
        let currentIndex = 0
        let currentLine = ''
        function saveLine() {
            if (currentLine.length === 0) return
            stepLines[currentIndex].push(currentLine.trim())
            currentLine = ''
        }
        lines.forEach(line => {
            if (line.length === 1 && +line > 0) {
                saveLine()
                currentIndex++
                stepLines[currentIndex] = []
            } else {
                if (startsWithCapitalLetter(line)) {
                    saveLine()
                    currentLine += ' ' + line
                } else {
                    currentLine += ' ' + line
                }
            }
        });
        if (currentLine.length > 0) saveLine()

        const steps = stepLines.map(sl => sl.join('\n\n'))

        this.setState({ parsedMethod: steps.join('\n\n--NEXT STEP--\n\n') })

    }

    addDetectedMethodSteps() {
        const { parsedMethod } = this.state
        const { addMethodSteps } = this.props
        const steps = parsedMethod.split('\n\n--NEXT STEP--\n\n')

        addMethodSteps(steps)
        this.setState({ addingFromImage: false, parsedMethod: '' })
    }

    render() {
        const {
            method,
            addMethodStep,
            updateMethodStep,
            removeMethodStep,
            addIngredient,
            updateIngredient,
            removeIngredient } = this.props;
        const { addingFromImage, parsedMethod } = this.state

        return <Box>
            <Box>
                {method.map((methodStep, stepIndex) => <MethodStep
                    key={`${stepIndex}`}
                    methodStep={methodStep}
                    stepIndex={stepIndex}
                    updateMethodStep={updateMethodStep}
                    removeMethodStep={removeMethodStep}
                    addIngredient={addIngredient}
                    updateIngredient={updateIngredient}
                    removeIngredient={removeIngredient} />)}
            </Box>
            {addingFromImage && <Box margin={{ top: 'medium' }}>
                <EditableBoxComponent
                    placeholder='Copy ingredients'
                    multiline={true}
                    value={parsedMethod}
                    update={value => console.log(value)}
                />
                <Box direction="row" align="center" justify="between">
                    <AddButton
                        label="Tidy gousto recipe"
                        onClick={() => this.tidyGousto()} />
                    <AddButton
                        label="Add Method Steps"
                        onClick={() => this.addDetectedMethodSteps()} />
                </Box>
            </Box>}
            <Box direction="row" align="center" justify="between">
                <AddButton
                    label="Add Method Step"
                    onClick={() => addMethodStep()} />
                <AddButton
                    label="Detect steps from image"
                    onClick={() => this.detextText()} />
            </Box>
        </Box>
    }
}
