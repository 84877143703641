import React from 'react';
import { Api, CancelToken, UrlCache } from '../../../services';
import { RecipeComponent } from './RecipeComponent';

export class EditableRecipePageComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      error: null,
      recipe: null,
      editing: false,
      viewIngredients: false,
      imgUrl: null,
      suggestedTags: [],
      confirmingRemove: false,
    };
  }

  componentDidMount() {
    this.cancelToken = new CancelToken();

    Api.tags(this.cancelToken)
      .then(({ tags }) => {
        this.setState({
          suggestedTags: tags
        });
      })
      .catch(err => {
        if (!this._isCanceled) {
          console.log('error getting tags', err);
        }
      });
  }

  componentWillUnmount() {
    this._isCanceled = true;
    this.cancelToken.cancel(e => console.log(e));
  }

  loadRecipe() {
    const id = this.props.recipeId;

    Api.recipe(id, this.cancelToken)
      .then(recipe => {
        this.setRecipe(recipe);
        if (recipe.canEdit)
          Api.metadata.updateLastViewed(id)
      })
      .catch(e => {
        if (!this._isCanceled) {
          console.log('Load recipe error', e)
          this.setState({
            recipe: null,
            loadedRecipeId: id,
            error: 'Recipe not found'
          })
        }
      });
  }

  viewImage() {
    const recipe = this.state.recipe;
    if (!recipe) {
      return;
    }

    if (recipe.metadata?.image) {
      UrlCache.get(recipe.id)
        .then(imgUrl => {
          this.setState({ imgUrl })
        })
        .catch(url => console.log(url))
    }
  }

  setRecipe(recipe, other) {
    this.setState({
      loadedRecipeId: recipe.id,
      recipe,
      ...other
    });
    this.viewImage();
  }

  toggle(property) {
    const newState = {};
    newState[property] = !this.state[property]
    this.setState(newState);
  }

  onCloseUploadImageModal() {
    this.setState({ uploading: false });
    this.viewImage();
  }

  async rename(name) {
    const recipeID = this.props.recipeId;
    const recipe = await Api.metadata.rename(recipeID, name)
    this.setRecipe(recipe);
  }

  async updateNotes(notes) {
    const recipeID = this.props.recipeId;
    const recipe = await Api.metadata.updateNotes(recipeID, notes)
    this.setRecipe(recipe);
  }

  async addStepIngredient(methodStepIndex, ingredientName) {
    const recipeID = this.props.recipeId;
    const recipe = await Api.addStepIngredient(recipeID, methodStepIndex, ingredientName)
    this.setRecipe(recipe);
  }

  async updateStepIngredient(stepIndex, ingredient, updates) {
    const recipeID = this.props.recipeId;
    const r = await Api.updateStepIngredient(recipeID, stepIndex, ingredient.ingredientid, updates);
    this.setRecipe(r)
  }

  async removeStepIngredient(methodStepIndex, ingredient) {
    const recipeID = this.props.recipeId;
    const r = await Api.removeStepIngredient(recipeID, methodStepIndex, ingredient.ingredientid);
    this.setRecipe(r)
  }

  async addIngredient(ingredientName) {
    const recipeID = this.props.recipeId;
    const r = await Api.addIngredient(recipeID, ingredientName);
    this.setRecipe(r)
  }

  async addIngredientBatch(input) {
    const recipeID = this.props.recipeId;
    const r = await Api.addIngredientBatch(recipeID, input);
    this.setRecipe(r)
  }

  async updateIngredient(ingredient, updates) {
    const recipeID = this.props.recipeId;
    const r = await Api.updateIngredient(recipeID, ingredient.ingredientid, updates);
    this.setRecipe(r)
  }

  async removeIngredient(ingredient) {
    const recipeID = this.props.recipeId;
    const r = await Api.removeIngredient(recipeID, ingredient.ingredientid);
    this.setRecipe(r)
  }

  async addMethodStep(description) {
    const recipeID = this.props.recipeId;
    const recipe = await Api.addMethodStep(recipeID, description)
    this.setRecipe(recipe);
  }

  async addMethodSteps(steps) {
    const recipeID = this.props.recipeId;
    const recipe = await Api.addMethodSteps(recipeID, steps)
    this.setRecipe(recipe);
  }

  async updateMethodStep(stepIndex, updates) {
    const recipeID = this.props.recipeId;
    const r = await Api.updateMethodStep(recipeID, stepIndex, updates);
    this.setRecipe(r)
  }

  async removeMethodStep(stepIndex) {
    const recipeID = this.props.recipeId;
    const r = await Api.removeMethodStep(recipeID, stepIndex);
    this.setRecipe(r, { editingStepIndex: null })
  }

  async toggleRecordChanges(shouldRecord) {
    const recipeID = this.props.recipeId;
    const r = await Api.metadata.toggleRecordChanges(recipeID, shouldRecord);
    this.setRecipe(r, { editingStepIndex: null })
  }

  async addSubRecipe(subRecipe) {
    const recipeID = this.props.recipeId;
    const recipe = await Api.addSubRecipe(recipeID, subRecipe.id)
    this.setRecipe(recipe);
  }

  async removeSubRecipe(subRecipe) {
    const recipeID = this.props.recipeId;
    const r = await Api.removeSubRecipe(recipeID, subRecipe.recipeid);
    this.setRecipe(r)
  }

  async addTag(tag) {
    const recipeID = this.props.recipeId;
    const recipe = await Api.metadata.addTag(recipeID, tag)
    this.setRecipe(recipe);
  }

  async removeTag(tag) {
    const recipeID = this.props.recipeId;
    const r = await Api.metadata.removeTag(recipeID, tag);
    this.setRecipe(r)
  }

  async updateViewableByEveryone(value) {
    const recipeID = this.props.recipeId;
    const r = await Api.metadata.viewableByEveryone(recipeID, value);
    this.setRecipe(r)
  }

  async addLink(link) {
    const recipeID = this.props.recipeId;
    const r = await Api.metadata.addLink(recipeID, link);
    this.setRecipe(r)
  }

  async updateLink(linkIdx, type, value) {
    const recipeID = this.props.recipeId;
    const r = await Api.metadata.updateLink(recipeID, linkIdx, type, value);
    this.setRecipe(r)
  }

  async removeLink(linkIdx) {
    const recipeID = this.props.recipeId;
    const r = await Api.metadata.removeLink(recipeID, linkIdx);
    this.setRecipe(r)
  }

  async addReview(updates) {
    const recipeID = this.props.recipeId;
    const recipe = await Api.addReview(recipeID, updates)
    this.setRecipe(recipe);
  }

  async updateReview(reviewIndex, update) {
    const recipeID = this.props.recipeId;
    const recipe = await Api.updateReview(recipeID, reviewIndex, update)
    this.setRecipe(recipe);
  }

  async removeReview(reviewIndex) {
    const recipeID = this.props.recipeId;
    const recipe = await Api.removeReview(recipeID, reviewIndex)
    this.setRecipe(recipe);
  }

  async loadFromGousto(reviewIndex) {
    const recipeID = this.props.recipeId;
    const recipe = await Api.loadFromGousto(recipeID, reviewIndex)
    this.setRecipe(recipe);
  }

  async removeRecipe(recipeId) {
    await Api.removeRecipe(recipeId);
    window.location = '/'
  }

  render() {
    const { recipeId } = this.props;
    const {
      loadedRecipeId,
      recipe,
      suggestedTags,
      imgUrl,
      error,
    } = this.state;

    if (recipeId !== loadedRecipeId) {
      this.loadRecipe()
    }

    return <RecipeComponent
      loading={recipe === null}
      recipe={recipe}
      error={error}
      suggestedTags={suggestedTags}
      imgUrl={imgUrl}
      rename={(...args) => this.rename(...args)}
      updateNotes={(...args) => this.updateNotes(...args)}
      addMethodStep={(...args) => this.addMethodStep(...args)}
      addMethodSteps={(...args) => this.addMethodSteps(...args)}
      updateMethodStep={(...args) => this.updateMethodStep(...args)}
      removeMethodStep={(...args) => this.removeMethodStep(...args)}
      addStepIngredient={(...args) => this.addStepIngredient(...args)}
      updateStepIngredient={(...args) => this.updateStepIngredient(...args)}
      removeStepIngredient={(...args) => this.removeStepIngredient(...args)}
      addIngredient={(...args) => this.addIngredient(...args)}
      addIngredientBatch={(...args) => this.addIngredientBatch(...args)}
      updateIngredient={(...args) => this.updateIngredient(...args)}
      removeIngredient={(...args) => this.removeIngredient(...args)}
      addSubRecipe={(...args) => this.addSubRecipe(...args)}
      removeSubRecipe={(...args) => this.removeSubRecipe(...args)}
      addTag={(...args) => this.addTag(...args)}
      removeTag={(...args) => this.removeTag(...args)}
      updateViewableByEveryone={(...args) => this.updateViewableByEveryone(...args)}
      addLink={(...args) => this.addLink(...args)}
      updateLink={(...args) => this.updateLink(...args)}
      removeLink={(...args) => this.removeLink(...args)}
      removeRecipe={(...args) => this.removeRecipe(...args)}
      addReview={(...args) => this.addReview(...args)}
      updateReview={(...args) => this.updateReview(...args)}
      removeReview={(...args) => this.removeReview(...args)}
      toggleRecordChanges={(...args) => this.toggleRecordChanges(...args)}
      reloadRecipe={(...args) => this.loadRecipe(...args)}
      loadFromGousto={(...args) => this.loadFromGousto(...args)}
    />
  }
}
