import { Auth, Hub } from 'aws-amplify';

export class Session {
    hubListen() {
        this.notifyUserUpdate();

        Hub.listen("auth", ({ payload: { event, data } }) => {
            console.log('Hub auth event', event, data)
            this.notifyUserUpdate();
        });
    }

    currentValue = null;
    nextId = 0
    subscriptions = {

    }

    notifyUserUpdate() {
        this.currentUser()
            .then(user => {
                this.currentValue = user;
                Object.keys(this.subscriptions)
                    .forEach(key => this.subscriptions[key](user));
            })
            .catch(err => {
                this.currentValue = null;
                Object.keys(this.subscriptions)
                    .forEach(key => this.subscriptions[key](null));
            });
    }

    onUserUpdated(fn) {
        try {
            fn(this.currentValue);
            const id = this.nextId++;
            this.subscriptions[id] = fn;
            return () => delete this.subscriptions[id];
        } catch (e) {
            console.error('error adding subscription', e);
            return () => { };
        }
    }

    signOut() {
        return Auth.signOut()
    }

    hostedUI() {
        Auth.federatedSignIn();
    }

    async currentSession() {
        try {
            const session = await Auth.currentSession();
            return session;
        } catch (e) {
            const loc = localStorage.getItem('testIdToken');
            if (!!loc) {
                return {
                    testToken: true,
                    idToken: {
                        jwtToken: loc
                    }
                }
            }
            return null;
        }
    }

    async currentUser() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return user;
        } catch (e) {
            const session = await this.currentSession();
            if (!!session) {
                return {
                    attributes: {
                        email: "test@test.com",
                        nickname: "TestUser"
                    },
                    signInUserSession: session
                }
            }
            return null;
        }
    }
}
