import React from 'react';
import { Button } from 'grommet';
import { Add } from 'grommet-icons';

export default function ({ ...rest }) {
    return <Button
        primary
        reverse
        color="neutral-4"
        icon={<Add />}
        {...rest} />
}