import React from 'react';
import { Box, TextInput } from 'grommet';
import { FormCheckmark, FormClose, FormTrash } from 'grommet-icons';

export class EditableTextBox extends React.Component {
    constructor() {
        super()
        this.state = this.startingState();
    }

    startingState() {
        return {
            updatedValue: undefined
        };
    }

    handleChange(event) {
        const value = event.target.value;

        this.setState({
            updatedValue: value
        })

        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(value)
        }
    }

    onSubmit() {
        const { onSubmit, value, onCancel } = this.props;
        const { updatedValue } = this.state;
        if (updatedValue !== undefined && value !== updatedValue) {
            onSubmit(updatedValue)
        }
    }

    resetState() {
        this.setState(this.startingState());
    }

    render() {
        const { value, onCancel, onRemove, placeholder } = this.props;
        const { updatedValue } = this.state;

        const allowRemove = !!onRemove

        const toggableOptions = [{
            icon: <FormCheckmark />,
            onClick: () => this.onSubmit()
        }, {
            icon: <FormClose />,
            onClick: () => onCancel()
        }]

        if (!!allowRemove) {
            toggableOptions.push({
                icon: <FormTrash />,
                onClick: () => onRemove()
            })
        }

        const displayValue = updatedValue === ''
            ? ''
            : updatedValue || value || ''

        return (
            <Box
                display="block"
                border="bottom"
                pad="small"
                height={{ min: 'unset' }}
            >
                <Box direction="row" justify="between">
                    <Box height="40px" width="100%" border={{ side: "bottom" }}>
                        <TextInput
                            plain
                            placeholder={placeholder || ''}
                            value={displayValue}
                            onChange={event => this.handleChange(event)}
                            onKeyUp={e => e.key === 'Enter' && this.onSubmit()}
                        />
                    </Box>
                    <Box direction="row" alignSelf="end">
                        {toggableOptions.map(({ icon, onClick }, idx) => <Box
                            hoverIndicator
                            key={idx}
                            onClick={onClick}
                            margin={{ horizontal: "xsmall" }}
                            background="accent-2">
                            {icon}
                        </Box>)}
                    </Box>
                </Box>
            </Box>
        )
    }
}