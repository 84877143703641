import React from 'react';
import { Grid, ResponsiveContext } from 'grommet';

export const ResponsiveListGrid = ({ children }) => {
    const grid = {
        small: ['flex'],
        medium: ['flex', 'flex']
    }
    return <ResponsiveContext.Consumer>
        {(responsive) => (
            <Grid
                columns={grid[responsive] || ['flex', 'flex', 'flex', 'flex']} rows={['auto']}
                gap='small'>
                {children}
            </Grid>
        )}
    </ResponsiveContext.Consumer>
}
